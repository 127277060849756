import React, { useRef, useEffect, useState, useCallback, useLayoutEffect, forwardRef } from 'react';
import { format, addDays, startOfWeek, startOfMonth, differenceInDays, addMonths, isSameMonth, isToday, subMonths, isSameDay, subDays, isValid } from 'date-fns';
import { cn } from '../shadcn/lib/utils';
import { debounce } from 'lodash';
import { AnchoredTooltipProvider, mergeRefs, useTooltipAnchor } from '../utils';

const TimelineContext = React.createContext<{
  visibleStartDate: Date;
  visibleEndDate: Date;
  getLeftPosition: (date: Date) => number;
  dayWidth: number;
  disabled: boolean;
  today: Date;
  headerRef: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  contentRef: (node: HTMLDivElement | null) => void;
  contentElement: HTMLDivElement | null;
  updateScroll: (newScrollLeft?: number, newScrollTop?: number) => void;
  scrollToDate: (date?: Date) => void;
  hoveredRowId: string | null;
  setHoveredRowId: React.Dispatch<React.SetStateAction<string | null>>;
  sidebarWidth: number;
  setSidebarWidth: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

export const TimelineRoot = forwardRef<HTMLDivElement, {
  className?: string;
  disabled?: boolean;
  today?: Date;
  children: React.ReactNode;
  initialDate?: Date;
  dayWidth?: number;
}>(({ className, disabled = false, today = new Date(), children, initialDate, dayWidth = 6 }, ref) => {
  const [visibleStartDate, setVisibleStartDate] = useState(() => subMonths(today, 24));
  const [visibleEndDate, setVisibleEndDate] = useState(() => addMonths(today, 24));

  const headerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [contentElement, setContentElement] = useState<HTMLDivElement | null>(null);
  const contentRef = useCallback((node: HTMLDivElement | null) => {
    setContentElement(node);
  }, []);

  const isInitialScrollCompleteRef = useRef(false);
  const lastExpandTimeRef = useRef(0);
  const expandingRef = useRef(false);
  const scrolledFirstTimeRef = useRef(false);

  const updateScroll = useCallback((newScrollLeft?: number, newScrollTop?: number) => {
    if (newScrollLeft !== undefined) {
      if (headerRef.current) headerRef.current.scrollLeft = newScrollLeft;
      if (contentElement) contentElement.scrollLeft = newScrollLeft;
    }
    if (newScrollTop !== undefined) {
      if (sidebarRef.current) sidebarRef.current.scrollTop = newScrollTop;
      if (contentElement) contentElement.scrollTop = newScrollTop;
    }
  }, [contentElement]);

  const getLeftPosition = useCallback((date: Date): number => {
    return differenceInDays(date, visibleStartDate) * dayWidth;
  }, [visibleStartDate, dayWidth]);

  const scrollToDate = useCallback((date: Date = today) => {
    const leftPosition = getLeftPosition(date);
    const centerOffset = contentElement ? contentElement.clientWidth / 2 : 0;
    const scrollPosition = leftPosition - centerOffset;
    updateScroll(scrollPosition, undefined);
  }, [getLeftPosition, today, updateScroll, contentElement]);

  useLayoutEffect(() => {
    if (
      !scrolledFirstTimeRef.current &&
      headerRef.current &&
      sidebarRef.current &&
      contentElement
    ) {
      scrollToDate(initialDate || today);
      scrolledFirstTimeRef.current = true;
    }
  }, [scrollToDate, initialDate, today, contentElement]);

  const updateDateRange = useCallback(() => {
    const weeksList: Date[] = [];
    const monthsList: Date[] = [];
    let currentWeek = startOfWeek(visibleStartDate);
    let currentMonth: Date | null = null;

    while (currentWeek <= visibleEndDate) {
      weeksList.push(currentWeek);
      
      if (!currentMonth || !isSameMonth(currentWeek, currentMonth)) {
        currentMonth = startOfMonth(currentWeek);
        monthsList.push(currentMonth);
      }
      
      currentWeek = addDays(currentWeek, 7);
    }

  }, [visibleStartDate, visibleEndDate]);

  useEffect(() => {
    updateDateRange();
    //setRows(generateSampleRows(visibleStartDate, visibleEndDate));
  }, [visibleStartDate, visibleEndDate, updateDateRange]);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 10;

    const performInitialScroll = () => {
      if (contentElement && !isInitialScrollCompleteRef.current) {
        const todayPosition = getLeftPosition(today);
        const { clientWidth } = contentElement;
        const scrollToPosition = todayPosition - clientWidth / 2;
        
        contentElement.scrollLeft = scrollToPosition;
        if (headerRef.current) {
          headerRef.current.scrollLeft = scrollToPosition;
        }
        
        // Check if the scroll was successful
        if (contentElement.scrollLeft === scrollToPosition) {
          isInitialScrollCompleteRef.current = true;
        } else if (retryCount < maxRetries) {
          retryCount++;
          requestAnimationFrame(performInitialScroll);
        } else {
          console.error('Failed to set initial scroll position after max retries');
          isInitialScrollCompleteRef.current = true;
        }
      }
    };

    requestAnimationFrame(performInitialScroll);

  }, [getLeftPosition, today.getDate(), today.getMonth(), today.getFullYear(), contentElement]);

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const [sidebarWidth, setSidebarWidth] = useState(0);

  const contextValue = {
    visibleStartDate,
    visibleEndDate,
    getLeftPosition,
    dayWidth,
    disabled,
    today,
    headerRef,
    sidebarRef,
    contentRef,
    contentElement,
    updateScroll,
    scrollToDate,
    hoveredRowId,
    setHoveredRowId,
    sidebarWidth,
    setSidebarWidth,
  };

  return (
    <TimelineContext.Provider value={contextValue}>
      <AnchoredTooltipProvider>
        <div ref={ref} className={cn("flex flex-col h-full", className)}>
          {children}
        </div>
      </AnchoredTooltipProvider>
    </TimelineContext.Provider>
  );
});
TimelineRoot.displayName = 'TimelineRoot';

// Export these components to be used in layouts
export const TimelineHeader = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const childrenArray = React.Children.toArray(children);
    const cornerChild = childrenArray.find(child => React.isValidElement(child) && child.type === TimelineCorner);
    const otherChildren = childrenArray.filter(child => child !== cornerChild);

    return (
      <div ref={ref} className={cn("flex flex-none", className)} {...props}>
        {cornerChild || <TimelineCorner />}
        <TimelineHeaderContent />
        {otherChildren}
      </div>
    );
  }
);
TimelineHeader.displayName = 'TimelineHeader';

export const TimelineBody = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn("flex flex-1 overflow-hidden", className)} {...props}>
      {children}
    </div>
  )
);
TimelineBody.displayName = 'TimelineBody';

// Rename the existing TimelineHeader to TimelineHeaderContent
const TimelineHeaderContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineHeader must be used within a TimelineRoot");
    const { visibleStartDate, visibleEndDate, getLeftPosition, dayWidth, today, headerRef, updateScroll } = context;

    const mergedRef = mergeRefs([forwardedRef, headerRef]);

    const [months, setMonths] = useState<Date[]>([]);
    const [weeks, setWeeks] = useState<Date[]>([]);
    const [totalWidth, setTotalWidth] = useState(0);

    const updateDateRange = useCallback(() => {
      const weeksList: Date[] = [];
      const monthsList: Date[] = [];
      let currentWeek = startOfWeek(visibleStartDate);
      let currentMonth: Date | null = null;

      while (currentWeek <= visibleEndDate) {
        weeksList.push(currentWeek);
        
        if (!currentMonth || !isSameMonth(currentWeek, currentMonth)) {
          currentMonth = startOfMonth(currentWeek);
          monthsList.push(currentMonth);
        }
        
        currentWeek = addDays(currentWeek, 7);
      }

      const totalDays = differenceInDays(visibleEndDate, visibleStartDate);
      const calculatedWidth = totalDays * dayWidth;

      setWeeks(weeksList);
      setMonths(monthsList);
      setTotalWidth(calculatedWidth);
    }, [visibleStartDate, visibleEndDate, dayWidth]);

    useEffect(() => {
      updateDateRange();
    }, [visibleStartDate, visibleEndDate, updateDateRange]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      updateScroll(e.currentTarget.scrollLeft, undefined);
    };

    const todayPosition = getLeftPosition(today);

    return (
      <div
        ref={mergedRef}
        className={cn("flex-1 overflow-x-scroll relative border-b", className)}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        onScroll={handleScroll}
        {...props}
      >
        <div style={{ width: `${totalWidth}px`, height: '40px' }}>
          {months.map((month, index) => (
            <div 
              key={index} 
              className="absolute top-0 h-full flex items-left text-sm font-bold uppercase"
              style={{ left: `${getLeftPosition(month)}px` }}
            >
              {format(month, 'MMMM')}
            </div>
          ))}
          {weeks.map((week, index) => (
            <div 
              key={index} 
              className="absolute flex items-center justify-center text-xs w-[20px] h-[20px]"
              style={{ 
                left: `${getLeftPosition(week) - 10}px`,
                top: '20px',
              }}
            >
              {format(week, 'd')}
            </div>
          ))}
        </div>
        <div 
          className="absolute flex items-center justify-center text-xs w-[20px] h-[20px] bg-red-500 text-white rounded-full z-10 pointer-events-none"
          style={{ 
            left: `${todayPosition - 10}px`,
            top: '18px',
          }}
        >
          {format(today, 'd')}
        </div>
      </div>
    );
  }
);
TimelineHeaderContent.displayName = 'TimelineHeaderContent';

export const TimelineCorner = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineCorner must be used within a TimelineRoot");
    const { sidebarWidth } = context;

    return (
      <div 
        ref={ref} 
        className={cn("flex-shrink-0 border-r border-b", className)} 
        style={{ width: sidebarWidth, height: '40px' }}
        {...props}
      />
    );
  }
);
TimelineCorner.displayName = 'TimelineCorner';

export const TimelineSidebar = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, style, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineSidebar must be used within a TimelineRoot");
    const { sidebarRef, updateScroll, contentElement, setSidebarWidth } = context;

    const [scrollbarHeight, setScrollbarHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        if (!contentElement) return;
        const { offsetHeight, clientHeight } = contentElement;
        const calculatedScrollbarHeight = offsetHeight - clientHeight;
        setScrollbarHeight(calculatedScrollbarHeight);
      });
      if (contentElement) {
        resizeObserver.observe(contentElement);
      }
      return () => {
        if (contentElement) {
          resizeObserver.disconnect();
        }
      };
    }, [contentElement]);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setSidebarWidth(containerRef.current.offsetWidth);
        }
      };

      const resizeObserver = new ResizeObserver(updateWidth);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      // Initial width update
      updateWidth();

      return () => resizeObserver.disconnect();
    }, [setSidebarWidth]);

    const mergedRef = mergeRefs([forwardedRef, sidebarRef, containerRef]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      updateScroll(undefined, e.currentTarget.scrollTop);
    };

    return (
      <div
        ref={mergedRef}
        className={cn("overflow-y-scroll border-r flex-shrink-0", className)}
        style={{ 
          ...style,
          scrollbarWidth: 'none', 
          msOverflowStyle: 'none',
        }}
        onScroll={handleScroll}
        {...props}
      >
        {children}
        {/* Add additional space to match the horizontal scrollbar */}
        <div style={{ height: `${scrollbarHeight}px` }} />
      </div>
    );
  }
);
TimelineSidebar.displayName = 'TimelineSidebar';

export const TimelineContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineContent must be used within a TimelineRoot");
    const { visibleStartDate, visibleEndDate, getLeftPosition, dayWidth, today, contentRef, updateScroll } = context;

    const mergedRef = mergeRefs([forwardedRef, contentRef]);

    const [totalWidth, setTotalWidth] = useState(0);

    const updateTotalWidth = useCallback(() => {
      const totalDays = differenceInDays(visibleEndDate, visibleStartDate);
      const calculatedWidth = totalDays * dayWidth;
      setTotalWidth(calculatedWidth);
    }, [visibleStartDate, visibleEndDate, dayWidth]);

    useEffect(() => {
      updateTotalWidth();
    }, [visibleStartDate, visibleEndDate, updateTotalWidth]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      updateScroll(e.currentTarget.scrollLeft, e.currentTarget.scrollTop);
    };

    const todayPosition = getLeftPosition(today);

    return (
      <div
        ref={mergedRef}
        className={cn("flex-1 overflow-scroll relative", className)}
        onScroll={handleScroll}
        {...props}
      >
        <div style={{ width: `${totalWidth}px`, minHeight: '100%', position: 'relative' }}>
          {/* Add month indicators */}
          {children}
          <div 
            className="absolute top-0 w-px bg-red-500 pointer-events-none"
            style={{
              left: `${todayPosition}px`,
              height: '100%',
              zIndex: 2,
            }}
          />
        </div>
      </div>
    );
  }
);
TimelineContent.displayName = 'TimelineContent';

export const TimelineTitle = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  rowId?: string,
}>(
  ({ className, children, rowId, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineLane must be used within a TimelineRoot");
    const { hoveredRowId, setHoveredRowId } = context;
    const [ element, setElement ] = useState<HTMLDivElement | null>(null);

    useEffect(() => {

      function handleMouseEnter() {
        if (rowId) {
          setHoveredRowId(rowId);
        }
      }

      function handleMouseLeave() {
        setHoveredRowId(prev => prev === rowId ? null : prev);
      }

      if (element) {
        element.addEventListener('mouseenter', handleMouseEnter);
        element.addEventListener('mouseleave', handleMouseLeave);
      }

      return () => {
        if (element) {
          element.removeEventListener('mouseenter', handleMouseEnter);
          element.removeEventListener('mouseleave', handleMouseLeave);
        }
      }

    }, [element, rowId, setHoveredRowId]);

    return (
      <div 
        ref={mergeRefs([ref, setElement])}
        className={cn(
          "h-8 flex items-center px-2 text-sm truncate",
          "data-[row-hover=true]:bg-[#303030]",
          className
        )}
        data-row-hover={rowId === hoveredRowId ? "true" : "false"}
        {...props}
      >
        {children}
      </div>
    );
  }
);
TimelineTitle.displayName = 'TimelineTitle';

export const TimelineLane = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  rowId: string,
  disabled?: boolean,
  className?: string,
  style?: React.CSSProperties,
  isHovered?: boolean,
  children?: React.ReactNode,
}>(
  ({ rowId, className, disabled, isHovered, children, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineLane must be used within a TimelineRoot");
    const { getLeftPosition, dayWidth, disabled: timelineDisabled, hoveredRowId, setHoveredRowId } = context;

    const [ element, setElement ] = useState<HTMLDivElement | null>(null);

    useEffect(() => {

      function handleMouseEnter() {
        if (rowId) {
          setHoveredRowId(rowId);
        }
      }

      function handleMouseLeave() {
        setHoveredRowId(prev => prev === rowId ? null : prev);
      }

      if (element) {
        element.addEventListener('mouseenter', handleMouseEnter);
        element.addEventListener('mouseleave', handleMouseLeave);
      }

      return () => {
        if (element) {
          element.removeEventListener('mouseenter', handleMouseEnter);
          element.removeEventListener('mouseleave', handleMouseLeave);
        }
      }

    }, [element, rowId, setHoveredRowId]);

    return (
      <div 
        ref={mergeRefs([ref, setElement])}
        className={cn(
          "h-[32px] flex items-center relative group",
          "data-[row-hover=true]:bg-[#303030]",
          className,
        )}
        data-row-hover={rowId === hoveredRowId ? "true" : "false"}
        {...props}
      >
        {children}
      </div>
    );
  }
);
TimelineLane.displayName = 'TimelineLane';

export const TimelineDateRange = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  start: Date,
  end: Date,
  onUpdate?: (start: Date, end: Date) => void | Promise<void>,
  onCustomDragStart?: (e: MouseEvent, cancel: () => void) => void | Promise<void>,
  onCustomDragEnd?: (e: MouseEvent | null) => void | Promise<void>,
  disabled?: boolean,
}>(({
  start,
  end,
  className,
  style,
  onUpdate,
  onCustomDragStart,
  onCustomDragEnd,
  disabled = onUpdate === undefined,
  ...props
}, ref) => {
  const context = React.useContext(TimelineContext);
  if (!context) throw new Error("TimelineDateRange must be used within a TimelineRoot");
  const { getLeftPosition, dayWidth, disabled: timelineDisabled } = context;

  const [ isMiddleHovered, setIsMiddleHovered ] = useState(false);
  const [ isStartHandleHovered, setIsStartHandleHovered ] = useState(false);
  const [ isEndHandleHovered, setIsEndHandleHovered ] = useState(false);
  const [ isStartHovered, setIsStartHovered ] = useState(false);
  const [ isEndHovered, setIsEndHovered ] = useState(false);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragType, setDragType] = useState<'start' | 'end' | 'middle' | null>(null);
  const [dragStartX, setDragStartX] = useState(0);
  const [initialStart, setInitialStart] = useState<Date | null>(null);
  const [initialEnd, setInitialEnd] = useState<Date | null>(null);
  const [draggedStart, setDraggedStart] = useState<Date | null>(null);
  const [draggedEnd, setDraggedEnd] = useState<Date | null>(null);
  const [draggedDuration, setDraggedDuration] = useState<number | null>(null);
  const [isDraggingStart, setIsDraggingStart] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const isCancelling = useRef(false);
  if (isCancelling.current) {
    isCancelling.current = false;
  }

  const { anchorRef, tooltipRef } = useTooltipAnchor<HTMLDivElement, HTMLDivElement>({
    gap: 10,
    allowedOrientations: ['top'],
    overrideShow:
      isStartHandleHovered
      || isEndHandleHovered
      || isStartHovered
      || isEndHovered
      || isMiddleHovered
      || isDragging
  });

  const cancelDrag = useCallback(() => {
    console.log("cancelDrag");
    isCancelling.current = true;
    setIsMouseDown(false);
    setIsDragging(false);
    setDragType(null);
    setInitialStart(null);
    setInitialEnd(null);
    setDraggedStart(null);
    setDraggedEnd(null);
    setDraggedDuration(null);
    setIsDraggingStart(false);
    onCustomDragEnd?.(null);
  }, []);

  const endDrag = useCallback(() => {
    setIsMouseDown(false);
    setIsDragging(false);
    setDragType(null);
    setInitialStart(null);
    setInitialEnd(null);
    setDraggedStart(null);
    setDraggedEnd(null);
    setDraggedDuration(null);
    setIsDraggingStart(false);
  }, []);

  const handleMouseDown = useCallback((event: React.MouseEvent, type: 'start' | 'end' | 'middle') => {
    if (disabled || timelineDisabled) return;
    setIsMouseDown(true);
    setDragType(type);
    setDragStartX(event.clientX);
    setInitialStart(start);
    setInitialEnd(end);
    setDraggedStart(start);
    setDraggedEnd(end);
    setDraggedDuration(differenceInDays(end, start));
    setIsDraggingStart(type === 'start' || type === 'middle');

    onCustomDragStart?.(event.nativeEvent, cancelDrag);
  }, [disabled, timelineDisabled, start, end, onCustomDragStart, cancelDrag]);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (!isMouseDown || !initialStart || !initialEnd || isCancelling.current) return;

    const deltaX = event.clientX - dragStartX;
    const daysDelta = Math.round(deltaX / dayWidth);

    if (!isDragging) {
      if (daysDelta !== 0) {
        setIsDragging(true);
      }
      return;
    }

    let newStart = initialStart;
    let newEnd = initialEnd;
    let newDuration = differenceInDays(initialEnd, initialStart);

    switch (dragType) {
      case 'start':
        newStart = addDays(initialStart, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
      case 'end':
        newEnd = addDays(initialEnd, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
      case 'middle':
        newStart = addDays(initialStart, daysDelta);
        newEnd = addDays(initialEnd, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
    }

    if (newDuration > 0) {
      setDraggedStart(newStart);
      setDraggedEnd(newEnd);
      setDraggedDuration(newDuration);
    }
  }, [isMouseDown, isDragging, dragStartX, dragType, initialStart, initialEnd, dayWidth]);

  const handleMouseUp = useCallback(async (e: MouseEvent) => {
    if (isMouseDown && draggedStart && draggedEnd) {
      onCustomDragEnd?.(e);
      if (draggedStart.getTime() !== start.getTime() || draggedEnd.getTime() !== end.getTime()) {
        await onUpdate?.(draggedStart, draggedEnd);
      }
    }
    endDrag();
  }, [isMouseDown, draggedStart, draggedEnd, start, end, onUpdate, onCustomDragEnd, endDrag]);

  useEffect(() => {
    if (isMouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseDown, handleMouseMove, handleMouseUp]);

  const displayStart = draggedStart || start;
  const displayEnd = draggedEnd || end;
  const displayDuration = draggedDuration || differenceInDays(end, start);

  return <>

    <div ref={tooltipRef} className="fixed bg-black/70 text-white text-xs font-light rounded-sm p-[2px] shadow-md -left-[9999px] -top-[9999px] z-50 pointer-events-none select-none">
      {`${displayDuration} ${displayDuration === 1 ? 'day' : 'days'}`}
    </div>

    <div
      ref={mergeRefs([ref, anchorRef])}
      className={cn(
        "absolute h-[20px] bg-white rounded-sm",
        className,
        "data-[dragging-start=true]:cursor-col-resize",
        "data-[dragging-middle=true]:cursor-grabbing",
        "data-[dragging-end=true]:cursor-col-resize"
      )}
      style={{
        ...style,
        left: `${getLeftPosition(displayStart)}px`,
        width: `${displayDuration * dayWidth}px`,
      }}
      data-dragging-start={dragType === 'start'}
      data-dragging-middle={dragType === 'middle'}
      data-dragging-end={dragType === 'end'}
      {...props}
      onMouseEnter={e => { setIsHovered(true); props.onMouseEnter?.(e); }}
      onMouseLeave={e => { setIsHovered(false); props.onMouseLeave?.(e); }}
    >

      <div
        className="absolute h-full pr-[16px] -left-[60px] w-[60px] min-w-[60px] flex items-center justify-end text-xs select-none cursor-default bg-purple-500/0"
        style={{
          opacity: isStartHandleHovered || isDraggingStart || isHovered ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        {isValid(displayStart) ? format(displayStart, 'MMM d') : ''}
      </div>
      <div
        className="absolute h-full pl-[16px] -right-[60px] w-[60px] min-w-[60px] flex items-center justify-start text-xs select-none cursor-default bg-yellow-500/0"
        style={{
          opacity: isEndHandleHovered || isDragging || isHovered ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        {format(displayEnd, 'MMM d')}
      </div>
      <div 
        className={cn(
          "absolute inset-0",
          "data-[dragging=idle]:cursor-grab",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        onMouseDown={(e) => handleMouseDown(e, 'middle')}
        onMouseEnter={() => setIsMiddleHovered(true)}
        onMouseLeave={() => setIsMiddleHovered(false)}
      />
      <div
        className={cn(
          "absolute h-full -left-[8px] w-[11px] min-w-[11px] bg-red-500/0",
          "data-[oneday=true]:-left-[11px]",
          "data-[twodays=true]:-left-[9px]",
          "data-[dragging=idle]:cursor-col-resize",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        data-oneday={displayDuration < 2}
        data-twodays={displayDuration >= 2 && displayDuration < 3}
        onMouseEnter={() => setIsStartHandleHovered(true)}
        onMouseLeave={() => setIsStartHandleHovered(false)}
        onMouseDown={(e) => handleMouseDown(e, 'start')}
      />
      <div
        className={cn(
          "absolute h-full -right-[8px] w-[11px] min-w-[11px] bg-green-500/0",
          "data-[oneday=true]:-right-[11px]",
          "data-[twodays=true]:-right-[9px]",
          "data-[dragging=idle]:cursor-col-resize",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        data-oneday={displayDuration < 2}
        data-twodays={displayDuration >= 2 && displayDuration < 3}
        onMouseEnter={() => setIsEndHandleHovered(true)}
        onMouseLeave={() => setIsEndHandleHovered(false)}
        onMouseDown={(e) => handleMouseDown(e, 'end')}
      />
    </div>
  </>
});

TimelineDateRange.displayName = 'TimelineDateRange';

export default TimelineRoot;