"use client";

import React, { useImperativeHandle, useRef, useCallback, useMemo, memo } from "react";
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DRAG_THRESHOLD, DEFAULT_GROUP_DEAD_ZONE, Group, Item, Percent, Pixels, ZoneLength, isGroup, isLengthWithinZone } from "./model";
import { arraysEqual, hasAttribute, useMemoizedCallback } from "./utils";
import { createCustomEvent } from "./events";
import { DogsSelectionChangeEventDetail } from "./events";

export interface AllowInsertFn<T> {
  (props: {
    insertedItems: (Item<T> | Group<T>)[],
    index: number,
    parentId: string | null
  }): boolean;
}

export interface AllowInsertExternalFn {
  (props: {
    insertedItems: (Item<any> | Group<any>)[],
    index: number,
    parentId: string | null,
    externalDataKey: string,
  }): boolean;
}

export interface GroupDeadZoneFn<T> {
  (props: {
    group: Group<T>,
    insertedItems: (Item<T> | Group<T>)[],
    depth: number,
    orientation: 'column' | 'row'
  }): DeadZone | undefined;
}

export type DeadZone = ZoneLength | { fromStart: ZoneLength, fromEnd: ZoneLength };

export interface SelectionManager {
  getSelectedIds: () => Set<string>;
  setSelectedIds: (selectedIds: Set<string>, target: EventTarget | null) => void;
}

export type UseDogsProps<T> = {
  items: (Item<T> | Group<T>)[];
  dragThreshold?: number;
  groupDeadZone?: DeadZone | GroupDeadZoneFn<T>;
  maxDepth?: boolean;
  allowInsert?: AllowInsertFn<T> | boolean;
  allowInsertExternal?: AllowInsertExternalFn | string;
  orientation?: 'column' | 'row';
  selectionMode?: 'none' | 'single' | 'multi';
  treatChildrenAsParentBody?: boolean;
  enableOrdering?: boolean;
  onSelectionChanged?: (selectedIds: Set<string>) => void;
  selectionManagerRef?: React.Ref<SelectionManager>;
  externalDataKey?: string;
  debugId?: string;
} & ({
  setItems: (newItems: (Item<T> | Group<T>)[]) => void
} | {
  insertItems: (insertedItems: (Item<T> | Group<T>)[], index: number, parentId: string | null) => void;
  insertExternalItems?: (insertedItems: (Item<any> | Group<any>)[], index: number, parentId: string | null, externalDataKey: string, setSelectedIds: (selectedIds: Set<string>) => void) => void;
})

interface IterateCommonProps {
  id: string,
  ref: React.RefCallback<HTMLElement>,
  depth: number,
  isFirst: boolean,
  isLast: boolean,
  isDragging: boolean,
  isDraggingThis: boolean,
  isDraggingAncestor: boolean,
  isDraggingGroup: boolean,
  isDropAllowed: boolean,
  isDropping: boolean,
  isDroppingOnThis: boolean,
  isDroppingOnStart: boolean,
  isDroppingOnEnd: boolean,
  isDroppingInside: boolean,
  isDroppingOnNextStart: boolean,
  isDroppingOnPreviousEnd: boolean,
  isDroppingOnNextSiblingStart: boolean,
  isDroppingOnPreviousSiblingEnd: boolean,
  isHovering: boolean,
  isSelected: boolean,
  setSelected: (selected: boolean, target: EventTarget | null, replace?: boolean) => void,
}

export interface IterateRoot<T> extends IterateCommonProps {
  iterate: () => IterateItem<T>[];
  itemType: 'root';
  isDroppingWithin: boolean,
  isDroppingWithinDescendant: boolean,
  isHoveringWithin: boolean,
  isHoveringWithinDescendant: boolean,
  isEmpty: boolean,
  isDroppingOnThis: boolean,
  isDroppingOnStart: boolean,
  isDroppingOnEnd: boolean,
  isDroppingInside: boolean,
};

export interface IterateGroup<T> extends IterateCommonProps {
  iterate: () => IterateItem<T>[];
  itemType: 'group';
  group: Group<T>,
  isFirstSibling: boolean,
  isLastSibling: boolean,
  isDroppingWithin: boolean,
  isDroppingWithinDescendant: boolean,
  isHoveringWithin: boolean,
  isHoveringWithinDescendant: boolean,
  isEmpty: boolean,
};

export interface IterateChild<T> extends IterateCommonProps {
  itemType: 'child',
  item: Item<T>,
  isFirstSibling: boolean,
  isLastSibling: boolean,
  isDroppingWithinParent: boolean,
  isDroppingOnParent: boolean,
  isDroppingOnParentStart: boolean,
  isDroppingOnParentEnd: boolean,
};

export type IterateDragImage<T> = {
  itemType: 'dragImage',
  ref: React.RefCallback<HTMLElement>,
  draggedItems: (Item<T> | Group<T>)[],
  draggedGroups: Group<T>[],
  draggedChildren: Item<T>[],
  isDropAllowed: boolean,
  dropTarget: { id: string, path: string[], item: (Item<T> | Group<T>) },
  isDroppingOnStart: boolean,
  isDroppingOnEnd: boolean,
  isDroppingWithin: boolean,
  draggedIds: Set<string>, // Added this line
  isExternalDrag: boolean, // Added this line
};

type IterateItem<T> = IterateRoot<T> | IterateGroup<T> | IterateChild<T> | IterateDragImage<T>;

export interface DragState {
  dropId: string | null;
  hoverPath: readonly string[] | null; // New property to track hover path
  isDragging: boolean;
  positionWithin: number | null;  // Replace isUpper with positionBetween
  isDroppingOnStart: boolean;
  isDroppingOnEnd: boolean;
  lastSelectedTarget: EventTarget | null;
  lastSelectedId: string | null;
  mouseDownId: string | null;
  mouseDownPos: { x: number, y: number } | null;
  selectedIds: Set<string>;
  mousedownIds: Set<string>;
  draggedIds: Set<string>;
  selectionRootPath: readonly string[] | null;
  shiftSelectAnchorId: string | null;
  isDropAllowed: boolean;
  isExternalDrag: boolean;
  mouseDownOnSelectedItem: boolean;
}

// Helper function to check if arrays are equal
function areArraysEqual(a: any[], b: any[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (!Object.is(a[i], b[i])) return false;
  }
  return true;
}

export interface DogsHookResult<T> {
  state: DragState;
  render: (props: {
    root: (props: IterateRoot<T> & { children: React.ReactNode }) => React.ReactNode,
    group?: (props: IterateGroup<T> & { children: React.ReactNode }) => React.ReactNode,
    child: (props: IterateChild<T>) => React.ReactNode,
    dragImage?: (props: IterateDragImage<T>) => React.ReactNode;
    rootRenderDeps?: (item: IterateRoot<T>) => any[] | null;
    groupRenderDeps?: (item: IterateGroup<T>) => any[] | null;
    childRenderDeps?: (item: IterateChild<T>) => any[] | null;
  }, renderKey?: any[]) => React.ReactNode;
  iterate: () => IterateItem<T>[];
  selectionManager: SelectionManager;
}

function parseItems<T>(items: (Item<T> | Group<T>)[]): [
  number,
  Record<string, string[]>,
  Record<string, Item<T> | Group<T>>,
  Record<string, string>,
  Record<string, string[]>,
  Record<string, Item<T> | Group<T>>,
  Record<string, number>,
  Record<number, string>,
] {
  const pathMap: Record<string, string[]> = {};
  const itemMap: Record<string, Item<T> | Group<T>> = {};
  const parentMap: Record<string, string> = {};
  const siblingsMap: Record<string, string[]> = {};
  const itemByPathMap: Record<string, Item<T> | Group<T>> = {};
  const globalIndexMap: Record<string, number> = {};
  const reverseGlobalIndexMap: Record<number, string> = {};

  let globalIndex = 0;

  function traverse(items: (Item<T> | Group<T>)[], currentPath: string[] = [], parentId?: string) {
    items.forEach((item, index) => {
      // Record global index.
      globalIndexMap[item.__id] = globalIndex;
      reverseGlobalIndexMap[globalIndex] = item.__id;
      globalIndex += 1;

      const itemPath = [...currentPath, index.toString()];
      pathMap[item.__id] = itemPath;
      itemByPathMap[JSON.stringify(itemPath)] = item;
      itemMap[item.__id] = item;

      if (parentId !== undefined) {
        parentMap[item.__id] = parentId;
      }

      siblingsMap[item.__id] = items.map(item => item.__id);

      if (isGroup(item)) {
        traverse(item.__children, [...itemPath, item.__id], item.__id);
      }
    });
  }

  traverse(items);

  return [globalIndex, pathMap, itemMap, parentMap, siblingsMap, itemByPathMap, globalIndexMap, reverseGlobalIndexMap];

}

type BoundingBox = {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

// Helper function to find an item and its parent
export function findItemAndParent<T>(items: (Item<T> | Group<T>)[], id: string, parent: Group<T> | null = null): [Item<T> | Group<T>, Group<T> | null, number] | [null, null, null] {
  for (let i = 0; i < items.length; i++) {
    if (items[i].__id === id) {
      return [items[i], parent, i];
    }
    const group = items[i];
    if (isGroup(group)) {
      const result = findItemAndParent(group.__children, id, group);
      if (result.some(x => x !== null)) return result;
    }
  }
  return [null, null, null];
};

// Helper function to remove an item
export function removeItem<T>(items: Item<T>[], id: string) {
  const result = findItemAndParent(items, id);
  if (result) {
    const [item, parent, index] = result;
    const targetArray = parent ? parent.__children : items;
    if (index !== null) {
      targetArray.splice(index, 1);
      return { item, originalIndex: index };
    }
  }
  return null;
};


export function useDogs<T>(props: UseDogsProps<T>): DogsHookResult<T> {

  const propsRef = React.useRef(props);
  propsRef.current = {
    // Default prop values
    allowInsert: true,
    dragThreshold: DEFAULT_DRAG_THRESHOLD,
    enableOrdering: false,
    ...props,
    orientation: props.orientation ?? 'column' as 'column',
    selectionMode: props.selectionMode ?? 'none' as 'none',
  };

  const countRef = React.useRef<number>(0);
  const pathMapRef = React.useRef<Record<string, string[]>>({});
  const itemMapRef = React.useRef<Record<string, Item<T> | Group<T>>>({});
  const parentMapRef = React.useRef<Record<string, string>>({});
  const siblingsMapRef = React.useRef<Record<string, string[]>>({});
  const itemByPathMapRef = React.useRef<Record<string, Item<T> | Group<T>>>({});
  const globalIndexMapRef = React.useRef<Record<string, number>>({});
  const reverseGlobalIndexMapRef = React.useRef<Record<number, string>>({});
  const [count, pathMap, itemMap, parentMap, siblingMap, itemByPathMap, globalIndexMap, reverseGlobalIndexMap] = React.useMemo(() => {
    const parsed = parseItems<T>(propsRef.current.items);
    return parsed;
  }, [
    propsRef.current.items,
  ]);
  countRef.current = count;
  pathMapRef.current = pathMap;
  itemMapRef.current = itemMap;
  parentMapRef.current = parentMap;
  siblingsMapRef.current = siblingMap;
  itemByPathMapRef.current = itemByPathMap;
  globalIndexMapRef.current = globalIndexMap;
  reverseGlobalIndexMapRef.current = reverseGlobalIndexMap;

  const refs = React.useRef<Map<string, Set<HTMLElement>>>(new Map());
  const listenersRef = React.useRef<Map<string, Partial<{
    click: (e: MouseEvent) => void,
    mousedown: (e: MouseEvent) => void,
    mouseenter: (e: MouseEvent) => void,
    mouseleave: (e: MouseEvent) => void,
    mousemove: (e: MouseEvent) => void,
  }>>>(new Map());

  const state = React.useRef<DragState>({
    dropId: null,
    hoverPath: null,
    isDragging: false,
    positionWithin: null,
    isDroppingOnStart: false,
    isDroppingOnEnd: false,
    lastSelectedId: null,
    lastSelectedTarget: null,
    mouseDownId: null,
    mouseDownPos: null,
    selectedIds: new Set(),
    mousedownIds: new Set(),
    draggedIds: new Set(),
    selectionRootPath: null,
    shiftSelectAnchorId: null,
    isDropAllowed: typeof propsRef.current.allowInsert === 'function' ? propsRef.current.allowInsert({ insertedItems: [], index: 0, parentId: null }) : propsRef.current.allowInsert !== false,
    isExternalDrag: false,
    mouseDownOnSelectedItem: false,
  });

  // Forces rerender
  const [_, dispatch] = React.useReducer((x: number) => x + 1, 0);

  // Selection

  const emitSelectionChangeEvent = React.useCallback((
    newSelection: Set<string>, 
    oldSelection: Set<string>, 
    target: EventTarget | null,
    originalEvent: Event | null = null
  ): boolean => {
    const event = createCustomEvent<DogsSelectionChangeEventDetail>('ondogsselectionchange', {
      newSelection: new Set(newSelection),
      oldSelection: new Set(oldSelection),
      originalEvent,
    });
    return target?.dispatchEvent(event) ?? false;
  }, []);

  const updateSelection = React.useCallback((
    newSelection: Set<string>, 
    target: EventTarget | null,
    originalEvent: Event | null = null
  ) => {
    const oldSelection = new Set(state.current.selectedIds);
    if (target === null || emitSelectionChangeEvent(newSelection, oldSelection, target, originalEvent)) {
      state.current.selectedIds.clear();
      Array.from(newSelection).forEach(id => state.current.selectedIds.add(id));
      dispatch();
      if (propsRef.current.onSelectionChanged) {
        propsRef.current.onSelectionChanged(new Set(state.current.selectedIds));
      }
    }
  }, [emitSelectionChangeEvent]);

  const selectionManager: SelectionManager = React.useMemo(() => ({
    getSelectedIds: () => new Set(state.current.selectedIds),
    setSelectedIds: (selectedIds: Set<string>, target: EventTarget | null = null) => {
      updateSelection(selectedIds, target);
    }
  }), [updateSelection]);

  useImperativeHandle(props.selectionManagerRef, () => selectionManager);

  // Non-event callbacks

  // Update the checkAllowDrop function
  const getAllowInsert = useMemoizedCallback(
    (
      insertedItems: (Item<T> | Group<T>)[],
      index: number,
      parentId: string | null
    ) => {
      const currentAllowDrop = propsRef.current.allowInsert;

      if (typeof currentAllowDrop === 'function') {
        return currentAllowDrop({ insertedItems, index, parentId });
      } else {
        return currentAllowDrop !== false;
      }
    },
    [propsRef],
    React.useCallback((insertedItems, index, parentId) => {
      // Generate a unique key based on the input and the current allowDrop function
      return [
        JSON.stringify(insertedItems.map(item => item.__id)),
        index,
        parentId,
        propsRef.current.allowInsert,
      ]
    }, [propsRef])
  );

  const getGroupDeadzone = useMemoizedCallback(
    (props: {
      group: Group<T>,
      insertedItems: (Item<T> | Group<T>)[],
      depth: number,
      orientation: 'column' | 'row',
    }) => {
      if (typeof propsRef.current.groupDeadZone !== 'function') {
        return propsRef.current.groupDeadZone;
      }
      return propsRef.current.groupDeadZone(props);
    },
    [propsRef.current.groupDeadZone],
    React.useCallback((_props) => {
      return [
        JSON.stringify(_props.group),
        JSON.stringify(_props.insertedItems.map(item => item.__id)),
        _props.depth,
        _props.orientation,
        propsRef.current.groupDeadZone,
      ]
    }, [propsRef])
  );

  const isAttributeEnabled = React.useCallback((element: HTMLElement | null, attributeName: string, refElement: HTMLElement): boolean => {
    if (!element || !refElement.contains(element)) return false; // Not within refElement

    let currentElement: HTMLElement | null = element;

    while (currentElement) {
      const attrValue = currentElement.getAttribute(attributeName);
      if (attrValue !== null) {
        return attrValue.toLowerCase() !== 'false' && attrValue !== '0' && attrValue !== '';
      }
      if (currentElement === refElement) break; // Stop at refElement if no attribute found
      currentElement = currentElement.parentElement;
    }

    return true; // Default to disabled if no attribute found up to refElement
  }, []);

  // Check target

  const doesTargetMatch = React.useCallback((target: EventTarget | null, id: string) => (
    (!!target && target instanceof HTMLElement) && 
    (refs.current.get(id)?.has(target) || target.getAttribute('dogs-id') === id)
  ), [refs]);

  const isChildOfDraggedGroup = React.useCallback((id: string): [boolean, string] => {
    const path = pathMapRef.current[id];
    let topmostParent = '';
    if (!path) return [false, topmostParent];

    for (let i = 0; i < path.length; i++) {
      if (state.current.draggedIds.has(path[i])) {
        topmostParent = path[i];
      }
    }

    return [topmostParent !== '' && topmostParent !== id, topmostParent];
  }, [pathMapRef, state]);

  // Event callbacks

  const handledEventsRef = React.useRef(new Set<Event>());

  const handleEvent = React.useCallback((eventHandler: (e: MouseEvent, id: string) => void, e: MouseEvent, id: string) => {
    if (!handledEventsRef.current.has(e)) {
      handledEventsRef.current.add(e);
      eventHandler(e, id);
      // Remove the event from the set after a short delay
      setTimeout(() => {
        handledEventsRef.current.delete(e);
      }, 0);
    }
  }, []);

  const serializeDraggedItems = React.useCallback((ids: string[]) => {
    const draggedItems = Array.from(ids)
      .map(id => itemMapRef.current[id])
      .filter(Boolean);
    const serialized = JSON.stringify({
      items: draggedItems,
      externalDataKey: propsRef.current.externalDataKey
    });
    return serialized;
  }, [propsRef, itemMapRef, state]);

  const deserializeDraggedItems = React.useCallback((serializedData: string) => {
    try {
      const { items, externalDataKey } = JSON.parse(serializedData);
      return { items, externalDataKey };
    } catch (error) {
      console.error('Error deserializing dragged items:', error);
      return null;
    }
  }, []);

  const startExternalDrag = React.useCallback((ids: string[]) => {
    if (!propsRef.current.externalDataKey) return;
    const serializedData = serializeDraggedItems(ids);
    localStorage.setItem('dogs-external', serializedData);
    const event = new Event('dogsexternaldragstart');
    window.dispatchEvent(event);
  }, [serializeDraggedItems]);

  const endExternalDrag = React.useCallback(() => {
    if (!propsRef.current.externalDataKey) return;
    if (localStorage.getItem('dogs-external')) {
      localStorage.removeItem('dogs-external');
      const event = new Event('dogsexternaldragend');
      window.dispatchEvent(event);
    }
    clearExternalDragData();
  }, []);

  const externalDragData = React.useRef<{ key: string, items: (Item<any> | Group<any>)[], externalDataKey: string } | null>(null);

  const checkExternalDrag = React.useCallback(() => {
    if (!propsRef.current.externalDataKey) return;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key === 'dogs-external') {
        const serializedData = localStorage.getItem(key);
        if (serializedData) {
          const dragData = deserializeDraggedItems(serializedData);
          if (dragData) {
            externalDragData.current = { key, ...dragData } as {
              key: string,
              items: (Item<any> | Group<any>)[],
              externalDataKey: string,
            };
            return externalDragData.current;
          }
        }
      }
    }
    return null;
  }, [deserializeDraggedItems, propsRef]);

  const clearExternalDragData = React.useCallback(() => {
    externalDragData.current = null;
  }, []);

  const getAllowInsertExternal = useMemoizedCallback(
    (
      insertedItems: any[],
      index: number,
      parentId: string | null,
      externalDataKey: string
    ) => {
      const currentAllowInsertExternal = propsRef.current.allowInsertExternal;

      if (typeof currentAllowInsertExternal === 'function') {
        return currentAllowInsertExternal({ insertedItems, index, parentId, externalDataKey });
      } else if (typeof currentAllowInsertExternal === 'string') {
        return currentAllowInsertExternal === externalDataKey;
      } else {
        return false;
      }
    },
    [propsRef],
    React.useCallback((insertedItems, index, parentId, externalDataKey) => {
      return [
        JSON.stringify(insertedItems),
        index,
        parentId,
        externalDataKey,
        propsRef.current.allowInsertExternal,
      ]
    }, [propsRef])
  );

  const onMouseDownItem = React.useCallback((e: MouseEvent, id: string) => {
    const refElements = refs.current.get(id);
    if (!refElements || refElements.size === 0) {
      return;
    }

    // Find the specific element that triggered the event
    const targetElement = e.target as HTMLElement;
    const refElement = Array.from(refElements).find(el => el.contains(targetElement));

    if (!refElement) return;

    const isDraggingEnabled = isAttributeEnabled(targetElement, 'dogs-dragging-enabled', refElement);
    const isSelectionEnabled = isAttributeEnabled(targetElement, 'dogs-selection-enabled', refElement);

    if (!isDraggingEnabled && !isSelectionEnabled) {
      return;
    }

    e.preventDefault();

    // Dragging logic
    if (isDraggingEnabled) {
      state.current.mouseDownPos = { x: e.clientX, y: e.clientY };
      const [isChild, topmostDraggedParent] = isChildOfDraggedGroup(id);
      if (isChild) {
        state.current.mouseDownId = topmostDraggedParent;
      } else {
        state.current.mouseDownId = id;
      }

      state.current.mousedownIds.clear();
      if (state.current.selectedIds.has(id) || state.current.selectedIds.has(topmostDraggedParent)) {
        Array.from(state.current.selectedIds).forEach(x => state.current.mousedownIds.add(x));
      } else {
        state.current.mousedownIds.add(id);
      }
    }

    // Selection logic
    if (isSelectionEnabled && propsRef.current.selectionMode !== 'none') {
      const path = pathMapRef.current[id];
      if (!path) return;

      if (propsRef.current.selectionMode === 'single') {
        if (!state.current.selectedIds.has(id)) {
          updateSelection(new Set([id]), targetElement, e);
        }
        state.current.mouseDownOnSelectedItem = true;
      } else if (propsRef.current.selectionMode === 'multi') {
        if (e.shiftKey && state.current.shiftSelectAnchorId) {
          let newSelectedIds = new Set<string>(state.current.selectedIds);

          const anchorIndex = globalIndexMapRef.current[state.current.shiftSelectAnchorId];
          const currentIndex = globalIndexMapRef.current[id];

          Object.keys(pathMapRef.current).forEach(otherId => {
            if (anchorIndex > currentIndex) {
              if (globalIndexMapRef.current[otherId] >= currentIndex && globalIndexMapRef.current[otherId] <= anchorIndex) {
                newSelectedIds.add(otherId);
              }
            } else {
              if (globalIndexMapRef.current[otherId] >= anchorIndex && globalIndexMapRef.current[otherId] <= currentIndex) {
                newSelectedIds.add(otherId);
              }
            }
          });

          updateSelection(newSelectedIds, targetElement, e);
        } else if (e.ctrlKey || e.metaKey) {
          const newSelectedIds = new Set(state.current.selectedIds);
          if (state.current.selectedIds.has(id)) {
            newSelectedIds.delete(id);
          } else {
            newSelectedIds.add(id);
          }
          updateSelection(newSelectedIds, targetElement, e);
        } else {
          if (!state.current.selectedIds.has(id)) {
            updateSelection(new Set([id]), targetElement, e);
          }
          else {
            state.current.mouseDownOnSelectedItem = true;
          }
        }
      }

      // Update shift select anchor
      state.current.shiftSelectAnchorId = id;
      state.current.lastSelectedId = id;
      state.current.lastSelectedTarget = targetElement;
    }

    dispatch();
  }, [state, isChildOfDraggedGroup, isAttributeEnabled, refs, updateSelection, pathMapRef, globalIndexMapRef, propsRef]);

  const getBoundingBoxForParentAndChildren = React.useCallback((parentId: string): BoundingBox | null => {
    const parentElements = refs.current.get(parentId);
    if (!parentElements || parentElements.size === 0) return null;

    const parentItem = itemMapRef.current[parentId];
    if (!isGroup(parentItem)) return null;

    let boundingBox: BoundingBox = {
      left: Infinity,
      top: Infinity,
      right: -Infinity,
      bottom: -Infinity,
      width: 0,
      height: 0,
      x: 0,
      y: 0
    };

    const updateBoundingBox = (rect: DOMRect) => {
      boundingBox.left = Math.min(boundingBox.left, rect.left);
      boundingBox.top = Math.min(boundingBox.top, rect.top);
      boundingBox.right = Math.max(boundingBox.right, rect.right);
      boundingBox.bottom = Math.max(boundingBox.bottom, rect.bottom);
    };

    // Include all parent elements in the bounding box
    parentElements.forEach(element => {
      updateBoundingBox(element.getBoundingClientRect());
    });

    // Include children in the bounding box
    parentItem.__children.forEach(child => {
      const childElements = refs.current.get(child.__id);
      if (childElements) {
        childElements.forEach(element => {
          updateBoundingBox(element.getBoundingClientRect());
        });
      }
    });

    // Calculate width, height, x, and y
    boundingBox.width = boundingBox.right - boundingBox.left;
    boundingBox.height = boundingBox.bottom - boundingBox.top;
    boundingBox.x = boundingBox.left;
    boundingBox.y = boundingBox.top;

    return boundingBox;
  }, [refs, itemMapRef]);

  const onMouseMoveItem = React.useCallback((e: MouseEvent, id?: string) => {
    if (!propsRef.current.enableOrdering) return;

    if (!state.current.isDragging && state.current.mouseDownPos !== null && state.current.mouseDownId !== null) {
      const dx = e.clientX - state.current.mouseDownPos.x;
      const dy = e.clientY - state.current.mouseDownPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance > (propsRef.current.dragThreshold || DEFAULT_DRAG_THRESHOLD)) {
        state.current.isDragging = true;
        state.current.draggedIds.clear();
        Array.from(state.current.mousedownIds).map(x => state.current.draggedIds.add(x));

        if (propsRef.current.externalDataKey) {
          startExternalDrag(Array.from(state.current.draggedIds));
        }
        else {
          state.current.isExternalDrag = false;
        }

        dispatch();
      }
    }

    else if (state.current.isDragging && !!id) {
      const currentY = e.clientY;
      const currentX = e.clientX;

      const [isChild, topmostDraggedParent] = isChildOfDraggedGroup(id);
      const targetId = isChild ? topmostDraggedParent : id;

      const tryDrop = (currentTargetId: string, useParentBoundingBox: boolean = false) => {
        const elements = refs.current.get(currentTargetId);
        if (!elements || elements.size === 0) return false;

        let rect: BoundingBox = {
          left: Infinity,
          top: Infinity,
          right: -Infinity,
          bottom: -Infinity,
          width: 0,
          height: 0,
          x: 0,
          y: 0
        };

        elements.forEach(el => {
          if (el) {
            const elRect = el.getBoundingClientRect();
            rect.left = Math.min(rect.left, elRect.left);
            rect.top = Math.min(rect.top, elRect.top);
            rect.right = Math.max(rect.right, elRect.right);
            rect.bottom = Math.max(rect.bottom, elRect.bottom);
          }
        });

        rect.width = rect.right - rect.left;
        rect.height = rect.bottom - rect.top;
        rect.x = rect.left;
        rect.y = rect.top;

        let parentId = null;

        const elementIsGroup = isGroup(itemMapRef.current[currentTargetId]);

        if (useParentBoundingBox && !elementIsGroup) {
          parentId = parentMapRef.current[currentTargetId];
          if (parentId) {
            const parentBoundingBox = getBoundingBoxForParentAndChildren(parentId);
            if (parentBoundingBox) {
              rect = parentBoundingBox;
              currentTargetId = parentId;
            }
          }
        }

        if ((currentY >= rect.top && currentY <= rect.bottom) || (currentX >= rect.left && currentX <= rect.right)) {
          const relativeY = (currentY - rect.top) / rect.height;
          const relativeX = (currentX - rect.left) / rect.width;

          const positionWithin = propsRef.current.orientation === 'row' ? relativeX : relativeY;

          let isDroppingOnStart = false;
          let isDroppingOnEnd = false;

          // Check if it's an external drag
          let insertedItems: (Item<T> | Group<T>)[] | any[];
          let isExternalDrag = false;

          if (externalDragData.current) {
            insertedItems = externalDragData.current.items;
            isExternalDrag = true;
          } else {
            insertedItems = Array.from(state.current.draggedIds)
              .map(id => itemMapRef.current[id])
              .filter(Boolean) as (Item<T> | Group<T>)[];
          }

          if (elementIsGroup) {
            const group = itemMapRef.current[currentTargetId] as Group<T>;
            const deadZone = getGroupDeadzone({
              group,
              insertedItems,
              depth: pathMapRef.current[currentTargetId].length - 1,
              orientation: propsRef.current.orientation || 'column',
            });

            if (deadZone) {
              const startZone = typeof deadZone === "object" && 'fromStart' in deadZone ? deadZone.fromStart : deadZone;
              const endZone = typeof deadZone === "object" && 'fromEnd' in deadZone ? deadZone.fromEnd : deadZone;
              const lengthFromStart: Percent = `${positionWithin * 100}%`;
              const lengthFromEnd: Percent = `${(1 - positionWithin) * 100}%`;
              const total: Pixels = `${propsRef.current.orientation === 'row' ? rect.width : rect.height}px`;
              isDroppingOnStart = isLengthWithinZone({ length: lengthFromStart, zone: startZone, total });
              isDroppingOnEnd = isLengthWithinZone({ length: lengthFromEnd, zone: endZone, total });
            } else {
              isDroppingOnStart = positionWithin <= 0.5;
              isDroppingOnEnd = positionWithin > 0.5;
            }
          } else {
            isDroppingOnStart = positionWithin <= 0.5;
            isDroppingOnEnd = positionWithin > 0.5;
          }

          // Calculate the drop index and parent
          let dropIndex: number;
          let dropParentId: string | null;

          // Simplify root dropping logic
          if (currentTargetId === '__root') {
            // Always drop at the start of the root
            dropParentId = null;
            dropIndex = 0;
            isDroppingOnStart = true;
            isDroppingOnEnd = false;
          } else if (elementIsGroup && !isDroppingOnStart && !isDroppingOnEnd) {
            // Dropping inside the group
            dropIndex = 0;
            dropParentId = currentTargetId;
          } else {
            const parentPath = pathMapRef.current[currentTargetId].slice(0, -1);
            dropParentId = parentPath.length > 0 ? parentPath[parentPath.length - 1] : null;
            const siblings = dropParentId ? (itemMapRef.current[dropParentId] as Group<T>).__children : propsRef.current.items;
            dropIndex = siblings.findIndex(item => item.__id === currentTargetId);
            if (isDroppingOnEnd) {
              dropIndex += 1;
            }
          }

          const elements = refs.current.get(currentTargetId);
          if (elements && elements.size > 0) {
            const isDroppingEnabled = Array.from(elements).some(el => 
              isAttributeEnabled(el, 'dogs-dropping-enabled', el)
            );

            let isDropAllowed = false;
            if (isDroppingEnabled) {
              if (isExternalDrag) {
                isDropAllowed = getAllowInsertExternal(insertedItems, dropIndex, dropParentId, externalDragData.current!.externalDataKey);
              } else {
                isDropAllowed = getAllowInsert(insertedItems, dropIndex, dropParentId);
              }
            }

            if (isDropAllowed) {
              // Only set drop states if dropping is allowed
              state.current.dropId = currentTargetId;
              state.current.positionWithin = positionWithin;
              state.current.isDropAllowed = isDropAllowed;
              state.current.isDroppingOnStart = isDroppingOnStart;
              state.current.isDroppingOnEnd = isDroppingOnEnd;
              state.current.isExternalDrag = isExternalDrag;
              dispatch();
              return true;
            }
          }
        }
        return false;
      };

      const tryDropRecursive = (currentTargetId: string, useParentBoundingBox: boolean = false): boolean => {
        if (tryDrop(currentTargetId, useParentBoundingBox)) {
          return true;
        }
      
        if (propsRef.current.treatChildrenAsParentBody) {
          const parentId = parentMapRef.current[currentTargetId];
          if (parentId) {
            return tryDropRecursive(parentId, true);
          }
        }
      
        return false;
      };

      // Try to drop on the current target and its ancestors
      if (targetId) {
        if (tryDropRecursive(targetId)) {
          dispatch();
        }
      } else {
        // Try dropping on the root when not over any item
        if (tryDrop('__root')) {
          dispatch();
        } else {
          // Reset drop state when not over any item or root
          state.current.dropId = null;
          state.current.positionWithin = null;
          state.current.isDropAllowed = true;
          state.current.isDroppingOnStart = false;
          state.current.isDroppingOnEnd = false;
          state.current.isExternalDrag = false;
          dispatch();
        }
      }
    }
  }, [state, isChildOfDraggedGroup, pathMapRef, itemMapRef, refs, getAllowInsert, getAllowInsertExternal, isAttributeEnabled, getBoundingBoxForParentAndChildren, checkExternalDrag]);

  const handleMouseUp = React.useCallback((e: MouseEvent) => {
    if (state.current.isDragging && state.current.draggedIds.size > 0 && state.current.dropId !== null && state.current.isDropAllowed) {
      if (!state.current.isExternalDrag) {
        // Handle internal drop
        const newItems = JSON.parse(JSON.stringify(propsRef.current.items)) as typeof propsRef.current.items;

        // Helper function to get the highest-level selected items
        const getHighestLevelItems = (selectedIds: Set<string>): string[] => {
          const selectedItems = Array.from(selectedIds);
          return selectedItems.filter(id => {
            const path = pathMapRef.current[id];
            return !selectedItems.some(otherId => {
              const otherPath = pathMapRef.current[otherId];
              return path.length > otherPath.length && path.every((segment, index) => segment === otherPath[index]);
            });
          });
        };

        // Get highest-level dragged items
        const highestLevelDraggedIds = getHighestLevelItems(state.current.draggedIds);

        // Remove dragged items and store their original indices
        const draggedItems = highestLevelDraggedIds
          .map(id => removeItem(newItems, id))
          .filter(Boolean);

        // Sort draggedItems based on their original indices
        draggedItems.sort((a, b) => a!.originalIndex - b!.originalIndex);

        // Simplify root dropping logic
        let dropResult = state.current.dropId === '__root' 
          ? [null, null, 0] as [null, null, number]
          : findItemAndParent(newItems, state.current.dropId);
        if (!dropResult && propsRef.current.treatChildrenAsParentBody) {
          const parentId = parentMapRef.current[state.current.dropId];
          if (parentId) {
            dropResult = findItemAndParent(newItems, parentId);
          }
        }

        const [dropTarget, dropParent, dropIndex] = dropResult;

        if (dropIndex !== null) {
          let targetArray = dropParent ? dropParent.__children : newItems;
          let insertIndex = dropIndex;
          let insertParentId = dropParent ? dropParent.__id : null;

          // Determine where to insert the dragged items
          if (isGroup(dropTarget) && !state.current.isDroppingOnStart && !state.current.isDroppingOnEnd) {
            // Dropping inside the group
            targetArray = dropTarget.__children;
            insertIndex = 0; // Insert at the beginning of the group
            insertParentId = dropTarget.__id; // Set the parent ID to the group's ID
          } else if (state.current.isDroppingOnEnd) {
            insertIndex = dropIndex + 1;
          }
          // If isDroppingOnStart is true, insertIndex remains as dropIndex

          // Insert dragged items in their original order
          const insertedItems = draggedItems.map(item => item!.item);
          targetArray.splice(insertIndex, 0, ...insertedItems);

          if ("setItems" in propsRef.current) {
            propsRef.current.setItems(newItems);
          } else {
            propsRef.current.insertItems(insertedItems, insertIndex, insertParentId);
          }
        }
      }

      else {
        // Handle external drop
        if ("insertExternalItems" in propsRef.current && propsRef.current.insertExternalItems) {
          if (externalDragData.current) {
            const { items: externalItems, externalDataKey } = externalDragData.current;
            let dropResult = state.current.dropId === '__root' 
              ? [null, null, 0] as [null, null, number]
              : findItemAndParent(propsRef.current.items, state.current.dropId);
            const [dropTarget, dropParent, dropIndex] = dropResult;
            if (dropIndex !== null) {
              let insertIndex = dropIndex;
              let insertParentId = dropParent ? dropParent.__id : null;

              if (isGroup(dropTarget) && !state.current.isDroppingOnStart && !state.current.isDroppingOnEnd) {
                insertIndex = 0;
                insertParentId = dropTarget.__id;
              } else if (state.current.isDroppingOnEnd) {
                insertIndex = dropIndex + 1;
              }

              propsRef.current.insertExternalItems(externalItems, insertIndex, insertParentId, externalDataKey, (selectedIds) => {
                updateSelection(selectedIds, e.target as EventTarget, e);
              });
            }
          }
        }
      }
    }

    // Handle unselection on mouse up
    if (propsRef.current.selectionMode !== 'none' && state.current.lastSelectedId && state.current.lastSelectedId === state.current.mouseDownId) {
      const id = state.current.lastSelectedId;
      if ((propsRef.current.selectionMode === 'multi') && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
        if (!state.current.isDragging || !state.current.mouseDownOnSelectedItem) {
          if (state.current.selectedIds.size !== 1 || !state.current.selectedIds.has(id)) {
            updateSelection(new Set([id]), state.current.lastSelectedTarget, e);
          }
        }
      }
    }

    // Reset drag state
    state.current.isDragging = false;
    state.current.dropId = null;
    state.current.mouseDownPos = null;
    state.current.mouseDownId = null;
    state.current.mousedownIds.clear();
    state.current.isDropAllowed = true;
    state.current.draggedIds.clear();
    state.current.positionWithin = null;
    state.current.isDroppingOnStart = false;
    state.current.isDroppingOnEnd = false;
    state.current.isExternalDrag = false;
    state.current.mouseDownOnSelectedItem = false;

    endExternalDrag();
    dispatch();
  }, [state, dispatch, endExternalDrag, updateSelection, propsRef]);


  const onMouseEnterItem = React.useCallback((e: MouseEvent, id: string) => {
    if (!doesTargetMatch(e.target, id)) return;
    const path = [...(pathMapRef.current[id] || []), id];
    if (!arraysEqual(state.current.hoverPath, path)) {
      state.current.hoverPath = path;
      dispatch();
    }
  }, [state, dispatch, doesTargetMatch, pathMapRef]);

  const onMouseLeaveItem = React.useCallback((e: MouseEvent, id: string) => {
    if (!doesTargetMatch(e.target, id)) return;
    const path = [...(pathMapRef.current[id] || []), id];

    // Find the deepest ancestor that still contains the mouse
    let newPath = [...path];
    while (newPath.length > 0) {
      const ancestorElements = refs.current.get(newPath[newPath.length - 1]);
      if (ancestorElements && Array.from(ancestorElements).some(el => el.contains(e.relatedTarget as Node))) {
        break;
      }
      newPath.pop();
    }

    if (!arraysEqual(state.current.hoverPath, newPath)) {
      state.current.hoverPath = newPath.length > 0 ? newPath : null;
      dispatch();
    }
  }, [state, dispatch, refs, doesTargetMatch, pathMapRef]);

  // Add this at the component level, outside of any hooks
  const documentWasDragging = React.useRef(false);

  // Inside your useDogs hook:
  const handleDocumentEvents = React.useCallback((e: MouseEvent) => {
    if (e.type === 'mousemove') {
      // Check if the mouse is outside the root container
      if (state.current.isDragging && !Array.from(refs.current.get('__root') || []).some(el => el.contains(e.target as Node))) {
        // Reset drop state when dragging outside of the root
        if (state.current.dropId !== null || state.current.positionWithin !== null || state.current.isDroppingOnStart || state.current.isDroppingOnEnd) {
          state.current.dropId = null;
          state.current.positionWithin = null;
          state.current.isDropAllowed = true;
          state.current.isDroppingOnStart = false;
          state.current.isDroppingOnEnd = false;
          state.current.isExternalDrag = false;
          dispatch();
        }
      }
      else if (!state.current.isDragging) {
        checkExternalDrag();
        if (externalDragData.current) {
          state.current.isDragging = true;
          state.current.isExternalDrag = true;
          state.current.draggedIds = new Set(externalDragData.current.items.map(item => item.__id));
          dispatch();
        }
        else {
          onMouseMoveItem(e);
        }
      }
      else {
        onMouseMoveItem(e);
      }
    }

    else if (e.type === 'mousedown') {
      documentWasDragging.current = false;
    }

    else if (e.type === 'mouseup') {
      documentWasDragging.current = documentWasDragging.current || state.current.isDragging;
      handleMouseUp(e);
    }

    else if (e.type === 'click') {
      if (documentWasDragging.current || state.current.isDragging) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      }
    }

    // Check for external drag start
  }, [handleMouseUp, onMouseMoveItem, propsRef]);

  React.useLayoutEffect(() => {
    console.log('mounting', propsRef.current.debugId)
    document.addEventListener('mousedown', handleDocumentEvents);
    document.addEventListener('mousemove', handleDocumentEvents);
    document.addEventListener('mouseup', handleDocumentEvents);
    document.addEventListener('click', handleDocumentEvents, true);

    return () => {
      console.log('unmounting', propsRef.current.debugId)
      document.removeEventListener('mousedown', handleDocumentEvents);
      document.removeEventListener('mousemove', handleDocumentEvents);
      document.removeEventListener('mouseup', handleDocumentEvents);
      document.removeEventListener('click', handleDocumentEvents, true);
    };
  }, [handleDocumentEvents]);

  const attachListeners = React.useCallback((
    id: string,
    element: HTMLElement,
    listeners: Partial<{
      click: (e: MouseEvent) => void,
      mousedown: (e: MouseEvent) => void,
      mouseenter: (e: MouseEvent) => void,
      mouseleave: (e: MouseEvent) => void,
      mousemove: (e: MouseEvent) => void,
    }>) => {

    const wrappedListeners: typeof listeners = {};

    for (const [event, listener] of Object.entries(listeners)) {
      if (listener) {
        wrappedListeners[event as keyof typeof listeners] = (e: MouseEvent) => {
          handleEvent(listener, e, id);
        };
      }
    }

    listenersRef.current.set(id, wrappedListeners);
    for(const [event, listener] of Object.entries(wrappedListeners)) {
      // @ts-ignore
      element.addEventListener(event, listener);
    }

  }, [listenersRef, handleEvent]);

  const detachListeners = React.useCallback((
    id: string,
    element: HTMLElement,
  ) => {
    const listeners = listenersRef.current.get(id) || {};
    for(const [event, listener] of Object.entries(listeners)) {
      // @ts-ignore
      element.removeEventListener(event, listener);
    }
  }, [listenersRef]);

  const iterate = React.useCallback((): IterateItem<T>[] => {
    const createRefCallback = (id: string) => (element: HTMLElement | null) => {
      if (element) {
        if (!refs.current.has(id)) {
          refs.current.set(id, new Set());
        }
        const elements = refs.current.get(id)!;
        if (!elements.has(element)) {
          elements.add(element);
          attachListeners(id, element, {
            mousedown: (e) => onMouseDownItem(e, id),
            mousemove: (e) => onMouseMoveItem(e, id),
            mouseenter: (e) => onMouseEnterItem(e, id),
            mouseleave: (e) => onMouseLeaveItem(e, id),
          });
        }
      } else {
        const elements = refs.current.get(id);
        if (elements) {
          elements.forEach(el => {
            detachListeners(id, el);
          });
          refs.current.delete(id);
        }
      }
    };

    const iterateItems = (items: (Item<T> | Group<T>)[], parentPath: string[] = []): IterateItem<T>[] => {
      return items.map((item, index) => {
        const path = [...parentPath, index.toString()];
        const id = item.__id;
        const siblings = siblingsMapRef.current[id];
        const i = siblings.findIndex(x => x === id);
        const globalIndex = globalIndexMapRef.current[id];
        const dropPath = state.current.dropId ? pathMapRef.current[state.current.dropId] ?? [] : [];
        const hoverPath = state.current.hoverPath || [];

        const commonProps = {
          id,
          ref: createRefCallback(id),
          depth: path.length - 1,
          isFirst: globalIndex === 0,
          isLast: globalIndex === countRef.current - 1,
          isFirstSibling: i === 0,
          isLastSibling: i === siblings.length - 1,
          isDragging: state.current.isDragging,
          isDraggingThis: state.current.draggedIds.has(id),
          isDraggingAncestor: Array.from(state.current.draggedIds).some(otherId => (pathMapRef.current[otherId] || []).includes(id)),
          isDraggingGroup: Array.from(state.current.draggedIds).some(x => isGroup(itemMapRef.current[x])),
          isDropAllowed: state.current.isDropAllowed,
          isDropping: state.current.dropId !== null,
          isDroppingOnThis: state.current.dropId === id,
          isDroppingOnStart: state.current.dropId === id && !!state.current.isDroppingOnStart,
          isDroppingOnEnd: state.current.dropId === id && !!state.current.isDroppingOnEnd,
          isDroppingInside: state.current.dropId === id && !state.current.isDroppingOnEnd && !state.current.isDroppingOnStart,
          isDroppingOnNextStart: !!state.current.isDroppingOnStart && state.current.dropId === (reverseGlobalIndexMapRef.current[globalIndex + 1] || false),
          isDroppingOnPreviousEnd: !!state.current.isDroppingOnEnd && state.current.dropId === (reverseGlobalIndexMapRef.current[globalIndex - 1] || false),
          isDroppingOnNextSiblingStart: !!state.current.isDroppingOnStart && state.current.dropId === (siblings[i + 1] || false),
          isDroppingOnPreviousSiblingEnd: !!state.current.isDroppingOnEnd && state.current.dropId === (siblings[i - 1] || false),
          isHovering: state.current.hoverPath ? state.current.hoverPath[state.current.hoverPath.length - 1] === id : false,
          isSelected: state.current.selectedIds.has(id),
          setSelected: (selected: boolean, target: EventTarget | null = null, replace: boolean = false) => {
            const newSelectedIds = new Set(state.current.selectedIds);
            if (replace) {
              newSelectedIds.clear();
            }
            if (!selected) newSelectedIds.delete(id);
            else newSelectedIds.add(id);
            updateSelection(newSelectedIds, target);
          },
        };

        if (isGroup(item)) {
          return {
            itemType: 'group' as const,
            iterate: () => iterateItems(item.__children, path),
            ...commonProps,
            group: item,
            isDroppingWithin: (dropPath.length > 0 && dropPath[dropPath.length - 1] === id) || (dropPath.length > 1 && dropPath[dropPath.length - 2] === id),
            isDroppingWithinDescendant: dropPath.includes(id),
            isHoveringWithin: (hoverPath.length > 0 && hoverPath[hoverPath.length - 1] === id) || (hoverPath.length > 1 && hoverPath[hoverPath.length - 2] === id),
            isHoveringWithinDescendant: hoverPath.includes(id),
            isEmpty: item.__children.length === 0,
          };
        } else {
          const parentId = parentMapRef.current[id];
          const parentPath = parentId ? pathMapRef.current[parentId] : [];
          return {
            itemType: 'child' as const,
            ...commonProps,
            item,
            isDroppingWithinParent: dropPath.length > 0 && parentPath.length > 0 && dropPath.slice(0, parentPath.length).join(',') === parentPath.join(','),
            isDroppingOnParent: state.current.dropId === parentId,
            isDroppingOnParentStart: !!state.current.isDroppingOnStart && state.current.dropId === parentId,
            isDroppingOnParentEnd: !!state.current.isDroppingOnEnd && state.current.dropId === parentId,
          };
        }
      });
    };

    const rootItem: IterateRoot<T> = {
      itemType: 'root',
      iterate: () => iterateItems(propsRef.current.items),
      id: '__root',
      ref: createRefCallback('__root'),
      depth: -1,
      isFirst: false,
      isLast: false,
      isDragging: state.current.isDragging,
      isDraggingThis: false,
      isDraggingAncestor: false,
      isDraggingGroup: false,
      isDropAllowed: state.current.isDropAllowed,
      isDropping: state.current.dropId !== null,
      isDroppingOnThis: state.current.isDragging && state.current.dropId === '__root',
      isDroppingInside: state.current.isDragging && state.current.dropId === '__root' && !state.current.isDroppingOnEnd && !state.current.isDroppingOnStart,
      isDroppingOnStart: state.current.isDragging && state.current.dropId === '__root' && !!state.current.isDroppingOnStart,
      isDroppingOnEnd: state.current.isDragging && state.current.dropId === '__root' && !!state.current.isDroppingOnEnd,
      isDroppingWithin: state.current.dropId !== null,
      isDroppingWithinDescendant: state.current.dropId !== null,
      isDroppingOnNextSiblingStart: false,
      isDroppingOnPreviousSiblingEnd: false,
      isDroppingOnNextStart: false,
      isDroppingOnPreviousEnd: false,
      isHovering: !!state.current.hoverPath && state.current.hoverPath.length === 0,
      isHoveringWithin: !!state.current.hoverPath && state.current.hoverPath.length === 1,
      isHoveringWithinDescendant: !!state.current.hoverPath,
      isSelected: false,
      setSelected: (selected: boolean, target: EventTarget | null = null, replace: boolean = false) => {
        if (!selected) {
          updateSelection(new Set([]), target);
        }
      },
      isEmpty: propsRef.current.items.length === 0,
    };

    const dragImageItem: IterateItem<T> | null = state.current.isDragging && state.current.dropId
      ? {
          itemType: 'dragImage',
          ref: (element: HTMLElement | null) => {
            // Handle drag image ref if needed
          },
          draggedItems: Array.from(state.current.draggedIds).map(id => itemMapRef.current[id]).filter(Boolean) as (Item<T> | Group<T>)[],
          draggedGroups: Array.from(state.current.draggedIds).map(id => itemMapRef.current[id]).filter(isGroup) as Group<T>[],
          draggedChildren: Array.from(state.current.draggedIds).map(id => itemMapRef.current[id]).filter(item => !isGroup(item)) as Item<T>[],
          isDropAllowed: state.current.isDropAllowed,
          dropTarget: {
            id: state.current.dropId,
            path: pathMapRef.current[state.current.dropId],
            item: itemMapRef.current[state.current.dropId]
          },
          isDroppingOnStart: !!state.current.isDroppingOnStart,
          isDroppingOnEnd: !!state.current.isDroppingOnEnd,
          isDroppingWithin: !state.current.isDroppingOnStart && !state.current.isDroppingOnEnd,
          draggedIds: state.current.draggedIds, // Added this line
          isExternalDrag: state.current.isExternalDrag, // Added this line
        }
      : null;

    return dragImageItem ? [rootItem, dragImageItem] : [rootItem];
  }, [updateSelection]);

  const renderCache = useRef<Map<string, { node: React.ReactNode, deps: any[] }>>(new Map());

  const render: DogsHookResult<T>['render'] = useCallback(({
    root: rootRenderFn,
    group: groupRenderFn,
    child: childRenderFn,
    dragImage: dragImageRenderFn,
    rootRenderDeps,
    groupRenderDeps,
    childRenderDeps,
  }) => {
    const dragImageContainerRef = useRef<HTMLDivElement | null>(null);

    const renderItem = (item: IterateItem<T>): [React.ReactNode, boolean] => {
      let cacheKey: string;
      let deps: any[] | null;

      switch (item.itemType) {
        case 'root':
          cacheKey = `root-${item.id}`;
          deps = rootRenderDeps ? rootRenderDeps(item) : null;
          break;
        case 'group':
          cacheKey = `group-${item.id}`;
          deps = groupRenderDeps ? groupRenderDeps(item) : null;
          break;
        case 'child':
          cacheKey = `child-${item.id}`;
          deps = childRenderDeps ? childRenderDeps(item) : null;
          break;
        case 'dragImage':
          cacheKey = `dragImage-`;
          deps = null;
          break;
      }

      const cachedResult = renderCache.current.get(cacheKey);
      let shouldRerender = !cachedResult || !deps || !areArraysEqual(cachedResult.deps, deps);

      if (shouldRerender) {
        //console.log(`${cacheKey} needs to re-render due to changed deps`);
      }

      if (!shouldRerender && (item.itemType === 'root' || item.itemType === 'group')) {
        const [renderedChildren, childrenChanged] = item.iterate().reduce(
          ([renderedChildren, anyChildChanged], child) => {
            const [renderedChild, childChanged] = renderItem(child);
            return [
              [...renderedChildren, renderedChild],
              anyChildChanged || childChanged
            ];
          },
          [[] as React.ReactNode[], false]
        );
        shouldRerender = childrenChanged;
        if (shouldRerender) {
          //console.log(`${cacheKey} needs to re-render due to changed children`);
        }
      }

      if (shouldRerender) {
        //console.log(`Re-rendering ${cacheKey}`);
        let renderedItem: React.ReactNode;

        switch (item.itemType) {
          case 'root':
          case 'group':
            const children = item.iterate().map(child => {
              const [renderedChild, childChanged] = renderItem(child);
              return renderedChild;
            });
            renderedItem = item.itemType === 'root' && rootRenderFn
              ? rootRenderFn({ ...item, children })
              : (groupRenderFn ? groupRenderFn({ ...(item as IterateGroup<T>), children }) : children);
            break;
          case 'child':
            renderedItem = childRenderFn(item);
            break;
          case 'dragImage':
            renderedItem = dragImageRenderFn ? dragImageRenderFn(item) : null;
            break;
        }

        if (deps !== null) {
          renderCache.current.set(cacheKey, { node: renderedItem, deps });
        }
        return [renderedItem, true];
      } else {
        return [cachedResult ? cachedResult.node : null, false];
      }
    };

    React.useEffect(() => {
      const onMove = (e: MouseEvent) => {
        dragImageContainerRef.current?.style.setProperty('transform', `translate(${e.clientX}px, ${e.clientY}px)`);
      };
      document.addEventListener('mousemove', onMove);
      return () => {
        dragImageContainerRef.current?.style.setProperty('transform', `translate(-9999px, -9999px)`);
        document.removeEventListener('mousemove', onMove);
      }
    }, []);

    const items = iterate();

    return (
      <>
        <div style={{ position: "fixed", top: 0, left: 0, width: 0, height: 0, zIndex: 9999 }}>
          <div ref={dragImageContainerRef} style={{ pointerEvents: "none", position: "absolute", transform: "translate(-9999px, -9999px)", zIndex: "9999"}}>
            <>{items.find(item => item.itemType === 'dragImage') ? renderItem(items.find(item => item.itemType === 'dragImage')!)[0] : null}</>
          </div>
        </div>
        {renderItem(items.find(item => item.itemType === 'root')!)[0]}
      </>
    );
  }, [iterate]);

  return {
    render,
    iterate,
    state: state.current,
    selectionManager,
  };

}