"use client";

import { TaskStatus } from "@palette.tools/model";
import { DatetimePopover } from "@palette.tools/react";
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import ImageFallback from "../image/ImageFallback";
import { DeadlineLabel } from "../labels";
import { StatusDropdownMenu } from "../menus";
import EditableTable from "./EditableTable";
import { TableHeaderCell } from "./shared";
import { Asset, Project, Task, UserProfile, Workspace, getPermissions, transact, useAuth } from "@palette.tools/model.client";
import { DogsSelectionChangeEvent, SelectionManager } from "@palette.tools/react.dogs";


interface TaskRow {
  id: string,
  name: string,
  assignee: string | undefined,
  deadline: number | null | undefined,
  status: TaskStatus | undefined,
  editable: boolean,
}

function convertTaskToRow(task: Task, editable: boolean): TaskRow {

  return {
    id: task.id,
    name: task.data.name || "",
    assignee: task.roles.assignee[0],
    deadline: task.data.deadline,
    status: task.data.status,
    editable,
  }
}

const AssetCell: React.FC<{asset?: Asset}> = ({ asset }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <ImageFallback
      fallback="none"
      src={asset?.data.thumbnail_url}
      width={30}
      height={20}
      className="rounded-md border-gray-300 bg-gray-500"
      alt={`Thumbnail for ${asset?.data.name || "unknown item"}`}
    />
    <p className="max-w-full line-clamp-2 break-all">
      {asset?.data.name}
    </p>
  </div>
}


const NameCell: React.FC<{name?: string}> = ({name}) => {
  return <div className="min-w-[150px] max-w-[300px]">
    <p className="max-w-full line-clamp-2 break-all">{name}</p>
  </div>
}

const DeadlineCell: React.FC<{deadline?: number | null, isComplete?: boolean, editable?: boolean, onSetTaskDeadline?: (datetime: Date | null) => void}> = ({
  deadline,
  isComplete,
  editable,
  onSetTaskDeadline,
}) => {

  const label = <DeadlineLabel
    timestamp={deadline}
    editable={editable}
    isComplete={isComplete}
  />

  return <div className="flex flex-col content-center items-center float-left ml-4" dogs-selection-enabled="false" dogs-dragging-enabled="false">
    {editable ? <DatetimePopover
      disable={!editable}
      selectedDatetime={deadline ? new Date(deadline) : undefined}
      onSelectDatetime={(datetime) => {
        if (editable) {
          onSetTaskDeadline?.(datetime);
        }
      }}
    >
      {label}
    </DatetimePopover> : label}
  </div>
}


const StatusCell: React.FC<{
  status?: number,
  editable?: boolean,
  onSelectStatus?: (status: number) => void,
}> = ({
  status,
  editable,
  onSelectStatus,
}) => {

  return <div className="flex flex-col content-center items-center float-left">
    <div onClick={(e) => {
      if (!editable) return;
      e.preventDefault(); e.stopPropagation();
    }}>
      <StatusDropdownMenu
        editable={editable}
        selectedStatus={status}
        onSelectStatus={onSelectStatus}
      />
    </div>
  </div>
}

interface MyTasksTableProps {
  tasks: Task[];
  assetsByTask: Record<string, Asset>;
  projectsByTask: Record<string, Project>;
  workspacesByTask: Record<string, Workspace>;
  onSelectTasks?: (selectedTasks: Task[]) => void;
  onSetTaskDeadline?: (task: Task, deadline: number | null) => void;
  onSetTaskStatus?: (task: Task, status: number) => void;
  className?: string;
}

export interface MyTasksTableHandle {
  clearSelection: () => void;
}

export const MyTasksTable = forwardRef<MyTasksTableHandle, MyTasksTableProps>(({
  tasks,
  assetsByTask,
  projectsByTask,
  workspacesByTask,
  onSelectTasks,
  onSetTaskDeadline,
  onSetTaskStatus,
  className,
}, ref) => {

  const { profile } = useAuth();

  // Convert user profiles to row model.
  const [rows, taskById] = React.useMemo(() => {
    if (!tasks) return [[], {} as Record<string, Task>];
    const taskById: Record<string, Task> = {};
    return [tasks.map(task => {
      taskById[task.id] = task;
      const permissionsProps = {
        profile,
        project: projectsByTask?.[task.id],
        workspace: workspacesByTask?.[task.id],
        task,
      };
      return convertTaskToRow(task, getPermissions(permissionsProps).canEditTask);
    }), taskById];
  }, [profile, tasks, projectsByTask, workspacesByTask]);

  // Table states
  const [sorting, setSorting] = React.useState<SortingState>([{
    id: "deadline",
    desc: false,
  }, {
    id: "status",
    desc: false,
  }]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});

  const selectionManagerRef = React.useRef<SelectionManager | null>(null);

  // Expose clearSelection method to parent
  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      selectionManagerRef.current?.setSelectedIds(new Set(), null);
    },
  }));

  const columns: ColumnDef<TaskRow>[] = useMemo(() => [

    {
      accessorKey: "item",
      header: ({ column }) => <TableHeaderCell column={column} text="Item" />,
      cell: ({ row }) => <AssetCell asset={assetsByTask[row.original.id]} />,
      sortingFn: (a, b) => {
        const assetA = assetsByTask[a.original.id];
        const assetB = assetsByTask[b.original.id];
        return (assetA?.data.name || "").localeCompare(assetB?.data.name || "");
      }
    },

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="Task"/>,
      cell: ({ row }) => <NameCell name={row.original.name} />,
    },

    {
      accessorKey: "deadline",
      header: ({ column }) => <TableHeaderCell column={column} text="Deadline"/>,
      sortingFn: "datetime",
      cell: ({ row }) =>
        <DeadlineCell
          deadline={row.original.deadline}
          isComplete={row.original.status === TaskStatus.Complete}
          editable={row.original.editable}
          onSetTaskDeadline={(datetime) => {
            onSetTaskDeadline?.(taskById[row.original.id], datetime ? datetime.getTime() : null);
          }}
        />,
    },

    {
      accessorKey: "status",
      header: ({ column }) => <TableHeaderCell column={column} text="Status"/>,
      cell: ({ row }) => <StatusCell
        status={row.original.status}
        editable={row.original.editable}
        onSelectStatus={(status) => {
          const task = taskById[row.original.id];
          if (!task || task.data.status === status) return;
          onSetTaskStatus?.(task, status);
        }}
      />
    },

  ], [tasks, assetsByTask, taskById, onSetTaskDeadline]);


  const table = useReactTable({
    data: rows,
    columns,
    enableSortingRemoval: true,
    enableMultiSort: true,
    isMultiSortEvent: (e) => true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  // Set initial selected tasks
  const hasAlreadySetInitialSelectedTasks = React.useRef(false);
  const firstSortedRowId = table.getSortedRowModel().rows[0]?.original.id;
  React.useEffect(() => {
    if (hasAlreadySetInitialSelectedTasks.current) {
      console.log("skipped");
      return;
    };
    if (firstSortedRowId && selectionManagerRef.current) {
      console.log("setting initial selected tasks", firstSortedRowId);
      selectionManagerRef.current.setSelectedIds(new Set([firstSortedRowId]), null);
      hasAlreadySetInitialSelectedTasks.current = true;
    }
    else if (!firstSortedRowId) {
      console.log("no first sorted row id");
    }
    else if (!selectionManagerRef.current) {
      console.log("no selection manager ref");
    }
  }, [tasks, firstSortedRowId, selectionManagerRef.current]);

  return <EditableTable
    containerClassName="flex-1 min-h-0 h-full"
    rowClassName="group h-min"
    cellClassName="px-2 py-4 align-middle"
    enableOrdering={false}
    selectionMode={"single"}
    table={table}
    getUuid={(row) => row.original.id}
    selectionManagerRef={selectionManagerRef}
    onSelectRows={(rows) => {
      console.log("selecting rows", rows);
      const selectedTasks = rows.map(x => tasks ? tasks[x.index] : undefined)
        .filter(x => !!x) as Task[];
      onSelectTasks && onSelectTasks(selectedTasks);
    }}
  />

});
