"use client";

import { TaskStatus } from "@palette.tools/model";
import { CallbackWithDeps, DatetimePopover, Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, UserPopover } from "@palette.tools/react";
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import ImageFallback from "../image/ImageFallback";
import { StatusDropdownMenu } from "../menus";
import EditableTable, { AllowInsertRowsFn } from "./EditableTable";
import { TableHeaderCell } from "./shared";
import { Asset, Category, Phase, Project, Task, UserProfile, Workspace, getPermissions, transact, useAuth } from "@palette.tools/model.client";
import { Avatar } from "../image";
import { UserIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Emoji } from "emoji-picker-react";
import { AllowInsertExternalFn, Group, Item, SelectionManager } from "@palette.tools/react.dogs";

interface TaskRow {
  id: string,
  name: string,
  category: Category | null,
  assignee: string | undefined,
  deadline: number | null | undefined,
  status: TaskStatus | undefined,
  phase: Phase | null,
  editable: boolean,
}

function convertTaskToRow(task: Task, editable: boolean, phase: Phase | null, category: Category | null): TaskRow {

  return {
    id: task.id,
    name: task.data.name || "",
    category,
    assignee: task.roles.assignee[0],
    deadline: task.data.deadline,
    status: task.data.status,
    phase,
    editable,
  }
}

async function convertTasksToRows(
  tasks?: Task[],
  profile?: UserProfile,
  phaseByTask?: Record<string, Phase | null>,
  categoryByTask?: Record<string, Category | null>,
): Promise<TaskRow[]> {
  if (!tasks) return [];
  return tasks.map(task => {
    const phase = phaseByTask?.[task.id];
    const permissionsProps = {
      profile,
      phase,
      task: task,
    };
    return convertTaskToRow(task, getPermissions(permissionsProps).canEditTask, phase || null, categoryByTask?.[task.id] || null);
  });
}


function convertRowToTask(tasks: Task[] | undefined, row: Row<TaskRow>) {
  return (tasks || []).find(x => x.id === row.original.id);
}


const CategoryCell: React.FC<{category: Category | null}> = ({ category }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <Emoji unified={category?.data.emoji || ""} size={16} />
    <p className="max-w-full line-clamp-2 break-all text-xs">
      {category?.data.name}
    </p>
  </div>
}


const AssetCell: React.FC<{asset?: Asset}> = ({ asset }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <ImageFallback
      fallback="none"
      src={asset?.data.thumbnail_url}
      width={30}
      height={20}
      className="rounded-md border-gray-300 bg-gray-500"
      alt={`Thumbnail for ${asset?.data.name || "unknown item"}`}
    />
    <p className="max-w-full line-clamp-2 break-all text-xs">
      {asset?.data.name}
    </p>
  </div>
}


const NameCell: React.FC<{name?: string}> = ({name}) => {
  return <div className="min-w-[150px] max-w-[300px]">
    <p className="max-w-full line-clamp-2 break-all text-xs">{name}</p>
  </div>
}

function AssigneeCell(props: {
  workspace: Workspace | null,
  project: Project | null,
  profiles: UserProfile[],
  task?: Task,
  editable?: boolean,
}) {

  const { profiles, task, editable } = props;
  const assignee = profiles?.find(x => task?.is_role("assignee", x.id));

  return <div className="flex flex-col content-center items-center float-left ml-4">
    {assignee
      ? <Avatar user={assignee} size={30} />
      : <UserIcon className="w-[15px] h-[15px]" />
    }
  </div>

}


const StatusCell: React.FC<{
  status?: number,
  editable?: boolean,
  onSelectStatus?: (status: number) => void,
}> = ({
  status,
  editable,
  onSelectStatus,
}) => {

  return <div className="flex flex-col content-center items-center float-left">
    <div onClick={(e) => {
      if (!editable) return;
      e.preventDefault(); e.stopPropagation();
    }}>
      <StatusDropdownMenu
        className="h-[30px] text-xs"
        editable={editable}
        selectedStatus={status}
        onSelectStatus={onSelectStatus}
      />
    </div>
  </div>
}

const PhaseCell: React.FC<{
  phases: Phase[],
  phase?: Phase | null,
  editable?: boolean,
  onSelectPhase?: (phase: Phase) => void,
}> = ({
  phases,
  phase,
  editable,
  onSelectPhase,
}) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Pointer down outside handler
  React.useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!contentRef.current?.contains(e.target as Node) && !triggerRef.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("pointerdown", handler);
    return () => {
      window.removeEventListener("pointerdown", handler);
    }
  }, []);

  return <div dogs-selection-enabled="false" className="flex flex-col content-center items-center float-left">
    <Select
      value={phase?.data.id || ""}
      onValueChange={(value) => {
          const phase = phases.find(x => x.data.id === value);
        if (!phase) return;
        onSelectPhase && onSelectPhase(phase);
      }}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <SelectTrigger ref={triggerRef} className="w-[180px] text-xs h-[30px]">
        <SelectValue placeholder="Unscheduled" />
      </SelectTrigger>
      <SelectContent
        ref={contentRef}
        onCloseAutoFocus={(e) => {
          e.preventDefault(); e.stopPropagation();
        }}
      >
        <SelectGroup>
          {phases.map(phase => (
            <SelectItem key={phase.id} value={phase.data.id || ""}>
              {phase.data.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  </div>
}


export const PhaseTasksTable = React.forwardRef<HTMLDivElement, {
  editable?: boolean,
  profiles: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  tasks: Task[];
  phases: Phase[];
  categoryByTask: Record<string, Category | null>;
  assetByTask: Record<string, Asset>;
  phaseByTask: Record<string, Phase | null>;
  className?: string;
  selectionManagerRef?: React.Ref<SelectionManager>;
  externalDataKey?: string;
  selectionMode?: "single" | "multi" | "none",
  allowInsert?: CallbackWithDeps<AllowInsertRowsFn<TaskRow>>,
  allowInsertExternal?: (CallbackWithDeps<AllowInsertExternalFn>) | string,
  insertItems?: (insertedRows: Row<TaskRow>[], index: number, parentRow: Row<TaskRow> | null) => void,
  insertExternalItems?: (insertedRows: (Item<any> | Group<any>)[], index: number, parentRow: Row<TaskRow> | null, key: string) => void,
  onSelectTasks?: (selectedTasks: Task[]) => void;
  onSelectPhase?: (task: Task, phase: Phase | null) => void;
}>(({
  editable = false,
  profiles,
  workspace,
  project,
  tasks,
  phases,
  categoryByTask,
  assetByTask,
  phaseByTask,
  className,
  selectionManagerRef,
  externalDataKey,
  selectionMode = "single",
  allowInsert,
  allowInsertExternal,
  insertItems,
  insertExternalItems,
  onSelectTasks,
  onSelectPhase,
}, ref) => {

  const { profile } = useAuth();

  // Convert user profiles to row model.
  const [isConverting, setConverting] = React.useState(true);
  const [localData, setLocalData] = React.useState<TaskRow[]>([]);

  // Table states
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});

  const columns: ColumnDef<TaskRow>[] = useMemo(() => [

    {
      accessorKey: "category",
      header: ({ column }) => <TableHeaderCell column={column} text="Category" className="text-xs h-[30px]" />,
      cell: ({ row }) => <CategoryCell category={categoryByTask?.[row.original.id] || null} />,
      sortingFn: (a, b) => {
        const categoryA = categoryByTask?.[a.original.id];
        const categoryB = categoryByTask?.[b.original.id];
        return (categoryA?.data.name || "").localeCompare(categoryB?.data.name || "");
      }
    },

    {
      accessorKey: "item",
      header: ({ column }) => <TableHeaderCell column={column} text="Item" className="text-xs h-[30px]" />,
      cell: ({ row }) => <AssetCell asset={assetByTask[row.original.id]} />,
      sortingFn: (a, b) => {
        const assetA = assetByTask[a.original.id];
        const assetB = assetByTask[b.original.id];
        return (assetA?.data.name || "").localeCompare(assetB?.data.name || "");
      }
    },

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="Task" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <NameCell name={row.original.name} />,
    },
    
    {
      accessorKey: "phase",
      header: ({ column }) => <TableHeaderCell column={column} text="Phase" className="text-xs h-[30px]"/>,
      sortingFn: (a, b) => {
        const phaseA = phaseByTask[a.original.id];
        const phaseB = phaseByTask[b.original.id];
        return (phaseA?.data.name || "").localeCompare(phaseB?.data.name || "");
      },
      cell: ({ row }) => <PhaseCell
        phases={phases}
        phase={phaseByTask?.[row.original.id]}
        editable={row.original.editable}
        onSelectPhase={(phase) => {
          const task = convertRowToTask(tasks, row);
          if (task && onSelectPhase) {
            onSelectPhase(task, phase);
          }
        }}
      />,
    },

    {
      accessorKey: "assignee",
      header: ({ column }) => <TableHeaderCell column={column} text="Assignee" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <AssigneeCell
        workspace={workspace}
        project={project}
        profiles={profiles}
        task={convertRowToTask(tasks, row)}
        editable={row.original.editable}
      />,
    },

    {
      accessorKey: "status",
      header: ({ column }) => <TableHeaderCell column={column} text="Status" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <StatusCell
        status={row.original.status}
        editable={row.original.editable}
        onSelectStatus={(status) => {
          const task = convertRowToTask(tasks, row);
          if (!task || task.data.status === status) return;
          transact(task.update({ status }));
        }}
      />
    },
    
  ], [tasks, assetByTask, phaseByTask, onSelectPhase]);

  useEffect(() => {
    if (!tasks) setLocalData([]);
    setConverting(true);
    async function _convertTasksToRows() {
      const rows = await convertTasksToRows(tasks, profile, phaseByTask, categoryByTask);
      setLocalData(rows);
    }
    _convertTasksToRows().finally(() => setConverting(false));
  }, [profile, tasks, phaseByTask, categoryByTask]);

  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  return <EditableTable
    containerClassName={cn("border-t-0", className)}
    rowClassName="group h-min"
    cellClassName="px-2 py-[2px] align-middle"
    enableOrdering={editable}
    selectionMode={selectionMode}
    table={table}
    getUuid={(row) => row.original.id}
    externalDataKey={externalDataKey}
    selectionManagerRef={selectionManagerRef}
    onSelectRows={(rows) => {
      const selectedTasks = tasks.filter(x => rows.some(y => y.original.id === x.id));
      onSelectTasks && onSelectTasks(selectedTasks);
    }}
    allowInsert={[() => false, []]}
    allowInsertWhileSorting={true}
    allowInsertExternal={allowInsertExternal}
    insertItems={insertItems}
    insertExternalItems={insertExternalItems}
    rowClassNameOverride={() => "border-transparent"}
    containerClassNameOverride={(props) => cn(
      props.isDroppingWithinDescendant ? "border-[1px] border-primary" : "border-[1px] border-transparent",
    )}
    tableHeaderClassNameOverride={(props) => cn(
      "after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-border",
    )}
  />

});
