"use client";

import React, { useCallback, useEffect } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {
    ExpandedState,
} from "@tanstack/table-core";

import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon, CopyIcon, FolderEditIcon, GripVerticalIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";

import {
    Button, ContextMenuItem, ContextMenuSeparator, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, ElementType, Progress, TypographyH4, TypographyMedium, cn,
} from "@palette.tools/react";

import { calculateTaskProgress } from "@palette.tools/model";
import { Asset, AssetGroup, Category, Project, Workspace, getPermissions, transact, transactDryRun, useAuth, usePermissions } from "@palette.tools/model.client";
import { EventWrappedTransactionChunk } from "@palette.tools/model.core";
import ImageFallback from "../image/ImageFallback";
import TextFill from "../typography/TextFill";
import EditableTable from "./EditableTable";
import getCustomOrderedGroupedRowModel, { CustomOrderedGroupsOptions, UNGROUPED_GROUP_ID } from "./getCustomOrderedGroupedRowModel";


interface AssetRowNameCell {
  label: string,
  thumbnailStorageURL: string,
}

interface AssetRow {
  id: string,
  name: AssetRowNameCell,
  completion: number,
}

function convertAssetToTableModel(asset: Asset): AssetRow {

  const tasks = asset?.links.task || [];

  const completion = calculateTaskProgress(tasks);

  return {
    id: asset.id,
    name: {
      label: asset.data.name || "",
      thumbnailStorageURL: asset?.data.thumbnail_url || "",
    },
    completion: completion,
  }
}

function convertGroupToTableModel(group: AssetGroup) {
  return {
    id: group.id,
    label: group.data.name || "",
    orderedKeys: (group.lists.asset || []).map(x => x.id),
  }
}

export const AssetTable: React.FC<{
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  onClickAsset?: (asset: Asset) => void,
  onClickCreateAsset?: () => void,
  onClickDuplicateAsset?: (asset: Asset) => void,
  onClickRenameAsset?: (asset: Asset) => void,
  onClickDeleteAsset?: (asset: Asset) => void,
  onClickCreateGroup?: () => void,
  onClickRenameGroup?: (group: AssetGroup) => void,
  onClickDeleteGroup?: (group: AssetGroup) => void,
}> = ({
  workspace,
  project,
  category,
  onClickAsset,
  onClickDuplicateAsset,
  onClickRenameAsset,
  onClickDeleteAsset,
  onClickRenameGroup,
  onClickDeleteGroup,
}) => {

  const { profile } = useAuth();
  const { canEditCategory } = usePermissions({ workspace, project, category });
  const [isDragging, setIsDragging] = React.useState(false);

  const localData = React.useMemo(() => (category?.lists.asset || []).map(convertAssetToTableModel), [category]);
  const localGroups: CustomOrderedGroupsOptions<AssetRow> = {
    uniqueKeyColumnId: "id",
    showUngroupedEvenIfEmpty: true,
    groups: (category?.lists.group__asset || []).map(convertGroupToTableModel),
  };
  const hasGroups = localGroups.groups.length > 0;
  const [sorting, setSorting] = React.useState<SortingState>([])

  // Get project dropdown menu
  const getRowDropdownMenu = (
    asset: Asset,
  ) => {

    const { canEditAsset, canDeleteAsset } = getPermissions({ profile, workspace, project, category, asset });

    let items: ElementType<typeof DropdownMenuItem>[] = [];
    if (!asset) return items;

    if (canEditAsset) {
      items.push(<DropdownMenuItem
        key="duplicate"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDuplicateAsset && onClickDuplicateAsset(asset);
        }}
      >
        <CopyIcon width={16} height={16} />&nbsp;&nbsp;Duplicate
      </DropdownMenuItem>)
    }
    if (canEditAsset) {
      items.push(<DropdownMenuItem
        key="rename"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickRenameAsset && onClickRenameAsset(asset);
        }}
      >
        <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }
    if (canDeleteAsset) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDeleteAsset && onClickDeleteAsset(asset);
        }}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    if (items.length > 0) return items;
  }


  // Get project context menu
  const getRowContextMenuItems = (
    row: Row<AssetRow>,
  ) => {

    let items: ElementType<typeof ContextMenuItem>[] = [];

    const asset = category?.links.asset?.find(x => x.id === row.original.id);
    if (!asset) return items;

    const { canEditAsset, canDeleteAsset } = getPermissions({ profile, workspace, project, category, asset });

    if (canEditAsset) {
      items.push(<ContextMenuItem
        key="duplicate_"
        onSelect={() => onClickDuplicateAsset && onClickDuplicateAsset(asset)}
      >
        <CopyIcon width={16} height={16} />&nbsp;&nbsp;Duplicate
      </ContextMenuItem>)
    }
    if (canEditAsset) {
      items.push(<ContextMenuItem
        key="rename_"
        onSelect={() => onClickRenameAsset && onClickRenameAsset(asset)}
      >
        <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </ContextMenuItem>)
      items.push(<ContextMenuSeparator key="separator1" />)
    }
    if (canDeleteAsset) {
      items.push(<ContextMenuItem
        key="delete_"
        onSelect={() => onClickDeleteAsset && onClickDeleteAsset(asset)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }

    return items;

  }


  const columns: ColumnDef<AssetRow>[] = [

    {
      accessorKey: "move",
      header: ({ column }) => {
        return <></>
      },
      cell: ({ row }) => {

        if (canEditCategory) {
          return <div
            data-hidehandle={sorting.length > 0}
            className="opacity-100 data-[hidehandle=true]:opacity-0"
            onClick={e => {e.preventDefault(); e.stopPropagation()}}
          >
            <GripVerticalIcon
              className={cn(isDragging ? "opacity-0" : "cursor-grab opacity-0 group-hover:opacity-100")}
            />
          </div>
        }
        return <></>

      },
    },

    {
      accessorKey: "name",
      header: ({ column }) => {
        const sortDirection = column.getIsSorted();
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
          Name&nbsp;{
            sortDirection ?
              sortDirection === "asc"
                ? <ArrowUpIcon className="ml-2 h-4 w-4 stroke-primary" />
                : <ArrowDownIcon className="ml-2 h-4 w-4 stroke-primary" />
              : <div className="ml-2 h-4 w-4" />
          }
        </Button>
        )
      },
      cell: ({ row }) => {
        const name: AssetRowNameCell = row.getValue("name") || {
          label: "",
          thumbnailStorageURL: "",
        }
        return <div className="flex flex-row gap-x-8 items-center">
          <ImageFallback
            className="rounded-md"
            src={name.thumbnailStorageURL}
            width="110"
            height="70"
            alt={`Thumbnail for ${row.getValue("name")}`}
          >
            <div className="h-full w-full bg-muted/80 flex items-center place-content-center rounded-md">
              <div className="w-1/3 h-1/3 flex items-center place-content-center text-muted-foreground">
                <TextFill>{name.label}</TextFill>
              </div>
            </div>
          </ImageFallback>
          <TypographyMedium className="max-w-[150px] line-clamp-2 text-ellipsis">{name.label}</TypographyMedium>
        </div>
      },
      sortingFn: (
        rowA,
        rowB,
        columnId
      ) => {
        const nameA: AssetRowNameCell = rowA.getValue("name");
        const nameB: AssetRowNameCell = rowB.getValue("name");
        return (nameA?.label || "").localeCompare(nameB?.label);
      }
    },

    {
      accessorKey: "completion",
      header: ({ column }) => {
        const sortDirection = column.getIsSorted();
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
            Tasks Complete&nbsp;{
              sortDirection ?
                sortDirection === "asc"
                  ? <ArrowUpIcon className="ml-2 h-4 w-4 stroke-primary" />
                  : <ArrowDownIcon className="ml-2 h-4 w-4 stroke-primary" />
                : <div className="ml-2 h-4 w-4" />
            }
          </Button>
        )
      },
      cell: ({ row }) => {
        const progress = (row.getValue("completion") as number || 0) * 100;

        return <div className="relative w-[130px] h-[44px]">
          <Progress
            indicatorClassName="absolute inset-0 bg-green-500"
            className="w-full h-full rounded-md"
            value={progress}
          />

          {/* Label div */}
          <div className="absolute inset-0 flex items-center justify-center">
            <TypographyH4>{Math.floor(progress)}%</TypographyH4>
          </div>
          </div>
        },
    },

    {
      accessorKey: "arrow",
      header: ({ column }) => {
        return <></>
      },
      cell: ({ row }) => {

        const asset = category?.links.asset?.find(x => x.id === row.original.id);
        if (!asset) return undefined;

        const { canEditAsset } = getPermissions({ profile, workspace, project, category, asset });

        const actionButton = <Button
          onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          variant="ghost"
          size="icon"
          className="w-[35px] h-[35px]">
            <>{canEditAsset ? <MoreHorizontalIcon size={24} className="hidden group-hover:inline-block"/> : undefined}
            <ChevronRightIcon size={24} className={cn(canEditAsset ? "inline-block group-hover:hidden" : "")} />
            </>
        </Button>

        const dropdownMenuContent = asset ? getRowDropdownMenu(asset) : undefined;

        const actionButtonMenuWrapped = dropdownMenuContent
          ? <DropdownMenu>
              <DropdownMenuTrigger asChild>{actionButton}</DropdownMenuTrigger>
              <DropdownMenuContent>{dropdownMenuContent}</DropdownMenuContent>
            </DropdownMenu>
          : actionButton;

        return actionButtonMenuWrapped;

      },
    },

  ];

  async function insertItems(insertedRows: Row<AssetRow>[], index: number, groupRow: Row<AssetRow> | null) {

    if (!workspace || !project || !category || !canEditCategory) return;

    const txs: EventWrappedTransactionChunk[] = [];

    // Inserting at the root.
    if (!groupRow || groupRow.id === UNGROUPED_GROUP_ID) {

      // Remove from original group entities.
      if (groupRow?.id === UNGROUPED_GROUP_ID) {
        category.links.group__asset?.forEach(groupEntity => {
          groupEntity.links.asset?.forEach(assetEntity => {
            if (insertedRows.map(x => x.original.id).includes(assetEntity.id)) {
              txs.push(...groupEntity.unlink("asset", assetEntity.id));
            }
          })
        })
      }

      // Non-grouped rows inserted at the root.
      const insertedNonGroupRows = insertedRows.filter(x => !x.getIsGrouped());
      const insertedKeys = insertedNonGroupRows.map(x => x.original.id);
      const filteredData = localData.filter(x => !insertedKeys.includes(x.id));
      const newOrder = [...filteredData.slice(0, index), ...insertedNonGroupRows.map(x => x.original), ...filteredData.slice(index)].map(x => x.id);
      if (JSON.stringify(newOrder) !== JSON.stringify(localData.map(x => x.id))) {
        txs.push(
          ...category.reorder_items("asset", newOrder, {
            after: (key, id) => [
              ...workspace.link(key, id),
              ...project.link(key, id),
            ]
          })
        );
      }

      // Grouped rows.
      const insertedGroupIds = insertedRows.filter(x => x.getIsGrouped()).map(x => x.id);
      const groupOrder = localGroups.groups.map(x => x.id);
      const groupIndex = index;
      const filteredGroupOrder = groupOrder.filter(x => !insertedGroupIds.includes(x));
      const newGroupOrder = [...filteredGroupOrder.slice(0, groupIndex), ...insertedGroupIds, ...filteredGroupOrder.slice(groupIndex)];
      if (JSON.stringify(groupOrder) !== JSON.stringify(newGroupOrder)) {
        txs.push(
          ...category.reorder_items("group__asset", newGroupOrder, {
            after: (key, id) => [
              ...workspace.link(key, id),
              ...project.link(key, id),
            ]
          })
        );
      }

    }

    else {

      if (insertedRows.some(x => x.getIsGrouped())) {
        console.warn("Cannot insert grouped rows into a group.");
        return;
      }

      // Get original group.
      const groupId = groupRow.id;
      const originalGroup = localGroups.groups.find(x => x.id === groupId);
      if (!originalGroup) {
        console.warn("Could not find original group", { groupId, localGroups });
        return;
      };

      // Insert keys into new group.
      const insertedKeys = insertedRows.map(x => x.original.id);
      const keysWithoutInserted = originalGroup ? originalGroup.orderedKeys.filter(x => !insertedKeys.includes(x)) : [];
      const newGroup = { ...originalGroup, orderedKeys: [...keysWithoutInserted.slice(0, index), ...insertedKeys, ...keysWithoutInserted.slice(index)] };

      // Get entity.
      const newGroupEntity = category.links.group__asset?.find(x => x.id === groupId);
      if (!newGroupEntity) {
        console.warn("Could not find group entity.");
        return;
      }

      const wouldHaveRemoved: [string, string[]][] = [];

      // Remove keys from original groups.
      await Promise.allSettled(localGroups.groups.map(async localGroup => {
        if (localGroup.id === groupId) return;
        const keysToRemove = localGroup.orderedKeys.filter(x => insertedKeys.includes(x));
        const localGroupEntity = category.links.group__asset?.find(x => x.id === localGroup.id);

        if (!!localGroupEntity && keysToRemove.length > 0) {
          wouldHaveRemoved.push([localGroup.id, keysToRemove]);
          txs.push(
            //...localGroupEntity.remove_items("asset", keysToRemove),
            ...keysToRemove.flatMap(x => localGroupEntity.unlink("asset", x)),
          )
        }

      }));

      if (JSON.stringify(originalGroup.orderedKeys) !== JSON.stringify(newGroup.orderedKeys)) {

        const reorders = newGroupEntity.reorder_items("asset", newGroup.orderedKeys, { after: (key, id) => [
          ...workspace.link(key, id),
          ...project.link(key, id),
        ] });
        const newKeys = newGroup.orderedKeys.filter(x => !originalGroup.orderedKeys.includes(x));

        txs.push(
          ...newKeys.flatMap(x => newGroupEntity.link("asset", x)),
          ...reorders,
        )

      }

    }

    if (txs.length > 0) {
      await transact(txs);
    }

  }

  const [expanded, setExpanded] = React.useState<ExpandedState>({[UNGROUPED_GROUP_ID]: true});

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    enableGrouping: true,
    enableExpanding: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getCustomOrderedGroupedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    manualGrouping: false,
    groupedColumnMode: false,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      expanded,
      grouping: [JSON.stringify(localGroups)],
    },
  })

  const expandedFirstTime = React.useRef<Set<string>>(new Set());
  useEffect(() => {
    localGroups.groups.forEach(({id}) => {
      if (!(expandedFirstTime.current.has(id))) {
        setExpanded(prev => {
          if (typeof prev === "boolean") return true;
          return {...prev, [id]: true}
        });
        expandedFirstTime.current.add(id);
      }
    });
  }, [table, JSON.stringify(localGroups)]);



  return <EditableTable
      containerClassName="max-w-[920px] min-w-[770px] flex-1 min-h-0 h-full"
      table={table}
      getUuid={(row) => typeof row.groupingValue === "string" ? row.groupingValue : row.original.id}
      insertItems={insertItems}
      rowClassName="group border-border border-b"
      cellClassName="px-2 py-4 align-middle"
      enableOrdering={canEditCategory && sorting.length < 1}
      onIsDraggingChanged={setIsDragging}

      allowInsert={[({ insertedRows, index, parentId }) => {
        if (!canEditCategory) {
          // console.warn("Can't edit category");
          return false;
        }

        // Can't move ungrouped group
        if (insertedRows.some(x => x.id === UNGROUPED_GROUP_ID)) {
          // console.warn("Can't move ungrouped group");
          return false;
        }

        // Can't place a group below the ungrouped group
        if (hasGroups && parentId === null && index >= localGroups.groups.length + 1) {
          // console.warn("Can't place a group below the ungrouped group", { index });
          return false;
        }

        // Can't put a group in a group
        if (parentId !== null && insertedRows.some(x => x.getIsGrouped())) {
          // console.warn("Can't put a group in a group");
          return false;
        }

        // Can't put an ungrouped item at the root level
        if (hasGroups && parentId === null && insertedRows.some(x => !x.getIsGrouped())) {
          // console.warn("Can't put an ungrouped item at the root level");
          return false;
        }

        return true;
      }, [canEditCategory, hasGroups]]}

      groupDeadZone={({ insertedRows }) => {
        if (!insertedRows.some(x => x.getIsGrouped())) return "1px"
      }}

      onClickRow={(row) => {
        if (!onClickAsset || !row.original.id) return;
        const asset = category?.links.asset?.find(x => x.id === row.original.id) || null;
        asset && onClickAsset(asset);
      }}
      getRowContextMenuItems={getRowContextMenuItems}
      getGroupRowDropdownMenuItems={(groupRow) => {
        const items: ElementType<typeof DropdownMenuItem>[] = [];
        if (!canEditCategory) return items;
        const group = category?.links.group__asset?.find(x => x.id === groupRow.id);

        items.push(
          <DropdownMenuItem key="rename" onSelect={() => { group && onClickRenameGroup?.(group)}}>
            <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
          </DropdownMenuItem>
        );
        items.push(
          <DropdownMenuItem key="delete" onSelect={() => { group && onClickDeleteGroup?.(group) }}>
            <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
          </DropdownMenuItem>
        );
        return items;
      }}
      dragImageText={(draggedRows) => {
        if (draggedRows.length !== 1) return `${draggedRows.length} item${draggedRows.length !== 1 ? 's' : ''}}`;
        const draggedNames = draggedRows.map(x => category?.links.asset?.find(y => y.id === x.original.id)?.data.name || category?.links.group__asset?.find(y => y.id === x.id)?.data.name || "Unknown");
        return draggedNames.join(", ");
      }}
    />
    {/* Debugging /*}
    {/*
    <div className="h-[1px]">
      <pre>{JSON.stringify({
      localGroups,
      serverGroups,
      lists: category?.serialize()['group__asset']?.map((x: any) => ({ name: x.name, list: x['list_item__asset__in__group__asset']?.map((y: any) => y['asset']) })),
      links: category?.serialize()['group__asset']?.map((x: any) => ({ name: x.name, list: x['asset'] }))
    }, null, 2)}</pre>
    </div>
    */}
}
